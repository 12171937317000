import { PartialJournal, PersonType } from '@models';
import { PartialJournalType } from 'src/enums/partial-journal-type';
import * as Yup from 'yup';

export function useValidationSchema() {
  return Yup.object<PartialJournal>().shape({
    name: Yup.string().nullable().required(),
    leader: Yup.mixed<PersonType>().nullable().required(),
    type: Yup.string<PartialJournalType>().nullable().required(),
  });
}
