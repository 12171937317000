/**
 * Region person primary journal assignment permissions
 */
export enum PermissionPersonAssignmentPrimaryJournal {
  /**
   * Prehľad členov hlavného denníka
   */
  PERSON_ASSIGNMENT_PRIMARY_JOURNAL_LIST = 'PERSON_ASSIGNMENT_PRIMARY_JOURNAL_LIST',

  /**
   * Pridávanie členov hlavného denníka
   */
  PERSON_ASSIGNMENT_PRIMARY_JOURNAL_CREATE = 'PERSON_ASSIGNMENT_PRIMARY_JOURNAL_CREATE',

  /**
   * Odstraňovanie členov hlavného denníka
   */
  PERSON_ASSIGNMENT_PRIMARY_JOURNAL_DELETE = 'PERSON_ASSIGNMENT_PRIMARY_JOURNAL_DELETE',
}
