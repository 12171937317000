import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { JournalEntry, Me } from '@models';
import { useContext } from 'react';

export const MECHANISM = 'MECHANISM';
export const WORK_DONE = 'WORK_DONE';
export const TIMESHEET = 'TIMESHEET';
export const ATTACHMENT = 'ATTACHMENT';

function useMechanismSource(): HookedSource<JournalEntry> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [MECHANISM]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journalEntry.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useWorkDoneSource(): HookedSource<JournalEntry> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [WORK_DONE]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journalEntry.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useTimesheetsHookedSource(): HookedSource<JournalEntry> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [TIMESHEET]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journalEntry.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useAttachmentsHookedSource(): HookedSource<JournalEntry> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [ATTACHMENT]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journalEntry.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useJournalEntryHookedSources(): HookedSource<JournalEntry> {
  const mechanisms = useMechanismSource();
  const workdone = useWorkDoneSource();
  const timesheets = useTimesheetsHookedSource();
  const attachments = useAttachmentsHookedSource();

  return { ...mechanisms, ...workdone, ...timesheets, ...attachments };
}
