import React, { useContext, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {
  NavigationContext,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
/* import { Messages } from '../../enums'; */
import { Me } from '@models';
import { Messages } from '@enums';

export function ProtectedRoute(props: RouteProps & { permissions: string[] }) {
  const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const permitted = props.permissions.some((perm) => hasPermission(perm));

    // TODO

    if (!permitted) {
      navigate('/');
      const warning = Messages.ProtectedRoute.ACCESS.WARNING;
      showSnackbar(warning[0], warning[1], true);
    }
  });

  return <Route {...props} />;
}
