import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Me, Mechanism, MechanismCategory, Supplier } from '@models';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  SUPPLIER_MECHANISM_CATEGORY_DICTIONARY,
  SUPPLIER_MECHANISM_DICTIONARY,
} from '../../supplier-hooked-sources';
import { MechanismCreateDialog } from './dialogs/mechanism-create-dialog';
import { useColumns } from './mechanisms-columns';
import { useFilterFields } from './mechanisms-filter-fields';
import { useValidationSchema as mechanismCategorySchema } from '../../../mechanism-category/mechanism-category-schema';
import { useValidationSchema as mechanismSchema } from '../../../mechanisms/mechanism-schema';

export function SupplierMechanismsFields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const supplierId = data?.id || '';
  const mechanismsSource = hookedSources[SUPPLIER_MECHANISM_DICTIONARY]?.source;
  const mechanismCategoriesSource =
    hookedSources[SUPPLIER_MECHANISM_CATEGORY_DICTIONARY]?.source;

  const mechanismFilters = useFilterFields();
  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;

  return (
    <>
      {data && (
        <SupplierDictionaryActionBar sourceUrl={EvidenceApiUrl.MECHANISMS} />
      )}
      <SupplierDictionaryField<MechanismCategory>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__MECHANISM_CATEGORY__TITLE"
            defaultMessage="Dodávateľský číselník - Kategória mechanizmu"
          />
        }
        sourceUrl={EvidenceApiUrl.MECHANISM_CATEGORY}
        source={mechanismCategoriesSource}
        useSchema={mechanismCategorySchema}
        disabled={disabled}
      />
      <SupplierDictionaryField<Mechanism>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__MECHANISMS__TITLE"
            defaultMessage="Dodávateľský číselník - Mechanizmy"
          />
        }
        sourceUrl={EvidenceApiUrl.MECHANISMS}
        source={mechanismsSource}
        disabled={disabled}
        createDialog={MechanismCreateDialog}
        useColumns={useColumns}
        filters={mechanismFilters}
        useSchema={mechanismSchema}
      />
    </>
  );
}
