import { TableFieldColumn } from '@eas/common-web';
import { useIntl } from 'react-intl';

export function useColumns<TBPP>(): TableFieldColumn<TBPP>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_TBPP__TABLE__COLUMN__ORDER',
        defaultMessage: 'Poradie',
      }),
      datakey: 'order',
      sortkey: 'order',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_TBPP__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 250,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_TBPP__TABLE__COLUMN__NAME',
        defaultMessage: 'Číslo TBPP',
      }),
      datakey: 'code',
      sortkey: 'code',
      width: 250,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_TBPP__TABLE__COLUMN__EXTERNAL_ID',
        defaultMessage: 'Externý identifikátor',
      }),
      datakey: 'externalId',
      sortkey: 'externalId',
      width: 200,
    },
  ];
}
