import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import {
  EvidenceApiUrl,
  JournalEntryState,
  Permission,
  PrimaryJournalState,
} from '@enums';
import { JournalEntry, Me } from '@models';
import { isEmpty } from 'lodash';
import { useContext } from 'react';

export function useCloseJournalEntry() {
  const {
    source: { data },
  } = useContext<DetailHandle<JournalEntry>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const entryId = data?.id;

  const isSigned = data?.state === JournalEntryState.SIGNED;

  const callApi = () => {
    return abortableFetch(`${EvidenceApiUrl.JOURNAL_ENTRY}/${entryId}/close`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const showButton =
    data?.journal?.state !== PrimaryJournalState.CANCELLED &&
    isSigned &&
    !isEmpty(data?.signatures) &&
    hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_CLOSE);

  return { callApi, showButton };
}
