import { abortableFetch, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useCallback, useContext } from 'react';

export function useSupplierDictionaryImport(sourceUrl: EvidenceApiUrl) {
  const { hasPermission } = useContext(UserContext);

  const callApi = useCallback(
    (id: string, formData: { file: File | null }) => {
      if (!formData.file) throw new Error('Nebol vložený CSV súbor');

      const dto = new FormData();
      dto.append('file', formData.file);

      return abortableFetch(sourceUrl + '/import', {
        method: 'POST',
        body: dto,
      });
    },
    [sourceUrl]
  );

  const hasMechanismPermission =
    sourceUrl === EvidenceApiUrl.MECHANISMS &&
    hasPermission(Permission.Mechanism.MECHANISM_IMPORT);

  const hasAbsencePermission =
    sourceUrl === EvidenceApiUrl.ABSENCE &&
    hasPermission(Permission.Absence.ABSENCE_IMPORT);

  const hasWorkPermission =
    sourceUrl === EvidenceApiUrl.WORK &&
    hasPermission(Permission.Work.WORK_IMPORT);

  const hasProfessionPermission =
    sourceUrl === EvidenceApiUrl.PROFESSION &&
    hasPermission(Permission.Profession.PROFESSION_IMPORT);

  const showButton =
    hasMechanismPermission ||
    hasAbsencePermission ||
    hasWorkPermission ||
    hasProfessionPermission;

  return { callApi, showButton };
}
