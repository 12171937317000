import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { EvidenceApiUrl, JournalEntryState, JournalTypeEnum } from '@enums';
import { JournalEntry } from '@models';

export function useJournalTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: JournalTypeEnum.PRIMARY, name: 'Hlavný' },
    { id: JournalTypeEnum.PARTIAL, name: 'Dielčí' },
  ]);
}

export function useJournalEntryStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: JournalEntryState.SIGNED, name: 'Podpísaný' },
    { id: JournalEntryState.OPENED, name: 'Otvorený' },
    { id: JournalEntryState.CLOSED, name: 'Uzavretý' },
  ]);
}

export const useGetWeather = () => {
  return async (data: JournalEntry) => {
    const weatherResponse = await fetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY}/${data?.id}/weather`
    );
    const weather = await weatherResponse.json();

    return { ...data, weather };
  };
};
