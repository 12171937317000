import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { Me, PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useContext } from 'react';

export function useReopenPrimaryJournalDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const callApi = (journalId: string, formValues: { reason: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.PRIMARY_JOURNAL}/${journalId}/reopen`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues.reason,
      }
    );
  };

  const showButton =
    data?.state === PrimaryJournalState.CLOSED &&
    hasPermission(Permission.PrimaryJournal.PRIMARY_JOURNAL_REOPEN) &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton };
}
