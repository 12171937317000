import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  eqFilterParams,
  FormAutocomplete,
  FormContext,
  FormNumberField,
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  UserContext,
} from '@eas/common-web';
import { useWorks } from '@modules/work/work-api';
import { useAssemblyTypes } from '../work-done-api';
import { useWorkTbpps } from '@modules/work-tbpp/work-tbpp-api';
import { Me, WorkDone } from '@models';
import { useWorkCategories } from '@modules/work-category/work-category-api';

export function WorkDoneCreateDialog() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { setFieldValue } = useContext(FormContext);

  const { category, type } = useFormSelector((values: WorkDone) => ({
    category: values.category,
    type: values.type,
  }));

  const workCategories = useWorkCategories();
  const works = useWorks(
    category
      ? eqFilterParams({ field: 'category.id', value: category.id })
      : undefined
  );
  const assemblyTypes = useAssemblyTypes();
  const tbpps = useWorkTbpps();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_DONE__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormAutocomplete
          name="category"
          source={workCategories}
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__CATEGORY"
              defaultMessage="Kategória práce"
            />
          }
          notifyChange={() => {
            if (type) setFieldValue('type', null);
          }}
        />
        <FormAutocomplete
          name="type"
          source={works}
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Práca"
            />
          }
          required
        />
        <FormSelect
          source={assemblyTypes}
          name="assemblyType"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__ASSEMBLY_TYPE"
              defaultMessage="Druh práce"
            />
          }
          valueIsId
        />
        <FormTextField
          name="type.units"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__WORK_UNITS"
              defaultMessage="Merná jednotka"
            />
          }
          disabled
        />
        <FormNumberField
          name="amount"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__AMOUNT"
              defaultMessage="Počet vykonanej práce za daný den"
            />
          }
        />
        <FormTextField
          name="section"
          label={
            <FormattedMessage
              id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__SECTION"
              defaultMessage="Úsek"
            />
          }
        />
        {showTBPP && (
          <FormAutocomplete
            name="type.tbpp"
            source={tbpps}
            label={
              <FormattedMessage
                id="ESD__WORK_DONE__COLLECTION__FIELD_LABEL__TBPP"
                defaultMessage="TBPP"
              />
            }
            disabled
          />
        )}
      </FormPanel>
    </>
  );
}
