/**
 * Person permissions
 */
export enum PermissionPerson {
  /**
   * Prehľad osôb
   */
  PERSON_LIST = 'PERSON_LIST',

  /**
   * Detail osoby
   */
  PERSON_DETAIL = 'PERSON_DETAIL',

  /**
   * Vytvorenie osoby
   */
  PERSON_CREATE = 'PERSON_CREATE',

  /**
   * Editácia osoby
   */
  PERSON_UPDATE = 'PERSON_UPDATE',

  /**
   * Zmazanie osoby
   */
  PERSON_DELETE = 'PERSON_DELETE',

  /**
   * Zneplatnenie osoby
   */
  PERSON_INVALIDATE = 'PERSON_INVALIDATE',

  /**
   * Obnovenie osoby
   */
  PERSON_REVALIDATE = 'PERSON_REVALIDATE',
}
