import { useContext } from 'react';
import { DetailToolbarBtnName, UserContext } from '@eas/common-web';
import { Me } from '@models';

export function useEvidencePermission({
  options,
}: {
  options: { [key in DetailToolbarBtnName]?: string[] };
}) {
  return function usePermissionIntern(button: DetailToolbarBtnName) {
    const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);

    return (options?.[button] ?? [])?.some((perm) => {
      return hasPermission(perm);
    });
  };
}
