/**
 * Typ podpisov
 */
export enum SignatureType {
  /**
   * Časové razítko
   */
  TIMESTAMP = 'TIMESTAMP',

  /**
   * Podpis stylusom
   */
  STYLUS = 'STYLUS',

  /**
   * Kvalifikovaný elektronický podpis
   */
  KEP = 'KEP',
}
