import React, { useMemo } from 'react';
import {
  AuthoredEvidence,
  InlineTableFieldCells,
  TableCells,
  TableColumn,
  TableFieldColumn,
  TableFilterCells,
} from '@eas/common-web';
import { PersonType, WorkGroup, PersonAutocomplete } from '@models';
import { usePersonsInlineTableAutocomplete } from '@modules/person/person-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { personLabelMapper } from '@utils/label-mapper';
import { useTenants } from '@modules/tenant/tenant-api';

export const useColumns = () => {
  const intl = useIntl();

  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<WorkGroup>();

  return [
    {
      datakey: 'tenant',
      filterkey: 'tenant.id',
      sortkey: 'tenant.name',
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__COLUMN__NAME',
        defaultMessage: 'Názov pracovnej skupiny',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    ...authoredColumns,
  ] as TableColumn<WorkGroup>[];
};

export const membersColumns: TableFieldColumn<PersonType>[] = [
  {
    datakey: 'email',
    name: (
      <FormattedMessage
        id="ESD__PERSON_COLLECTION__TABLE__COLUMN__EMAIL"
        defaultMessage="E-mail"
      />
    ),
    width: 200,
  },
  {
    datakey: 'team',
    name: (
      <FormattedMessage
        id="ESD__PERSON_COLLECTION__TABLE__COLUMN__TEAM"
        defaultMessage="Tím"
      />
    ),
    width: 100,
  },
  {
    datakey: 'profession.name',
    name: (
      <FormattedMessage
        id="ESD__PERSON_COLLECTION__TABLE__COLUMN__PROFESSION"
        defaultMessage="Pracovná pozícia"
      />
    ),
    width: 100,
  },
];

export const useMembersColumns = (
  collectionDatakey = 'members'
): TableFieldColumn<PersonType>[] => {
  const AutocompleteCell =
    InlineTableFieldCells.useInlineAutocompleteCellFactory<
      PersonType,
      PersonAutocomplete
    >({
      dataHook: usePersonsInlineTableAutocomplete,
      collectionDatakey: collectionDatakey,
      isSubkey: false,
      labelMapper: personLabelMapper,
    });

  return useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="ESD__PERSON_COLLECTION__TABLE__COLUMN__AUTOCOMPLETE"
            defaultMessage="Člen"
          />
        ),
        datakey: 'label',
        width: 300,
        CellComponent: AutocompleteCell,
      },
      ...membersColumns,
    ],
    [AutocompleteCell]
  );
};
