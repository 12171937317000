export enum AssignmentType {
  /**
   * Hlavní denník - priamy člen
   */
  PRIMARY_JOURNAL = 'PRIMARY_JOURNAL',

  /**
   * Hlavní deník - nepriamy člen, užívateľ je členom dielčieho denníka
   */
  PRIMARY_JOURNAL_READ_ONLY = 'PRIMARY_JOURNAL_READ_ONLY',

  /**
   * Hlavní deník
   */
  PARTIAL_JOURNAL = 'PARTIAL_JOURNAL',

  /**
   * Hlavní deník
   */
  SUPPLIER = 'SUPPLIER',
}
