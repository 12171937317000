/**
 * Region journal entry's work done persmissions
 */
export enum PermissionJournalEntryWorkDone {
  /**
   * Prehľad vykonanej práce denných záznamov
   */
  JOURNAL_ENTRY_WORK_DONE_LIST = 'JOURNAL_ENTRY_WORK_DONE_LIST',

  /**
   * Detail vykonanej práce denného záznamu
   */
  JOURNAL_ENTRY_WORK_DONE_DETAIL = 'JOURNAL_ENTRY_WORK_DONE_DETAIL',

  /**
   * Pridanie vykonanej práce denného záznamu
   */
  JOURNAL_ENTRY_WORK_DONE_CREATE = 'JOURNAL_ENTRY_WORK_DONE_CREATE',

  /**
   * Editácia vykonanej práce denného záznamu
   */
  JOURNAL_ENTRY_WORK_DONE_UPDATE = 'JOURNAL_ENTRY_WORK_DONE_UPDATE',

  /**
   * Zmazanie vykonanej práce denného záznamu
   */
  JOURNAL_ENTRY_WORK_DONE_DELETE = 'JOURNAL_ENTRY_WORK_DONE_DELETE',
}
