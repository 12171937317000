import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableFilterCells,
  UserContext,
} from '@eas/common-web';
import { Me, Work } from '@models';
import { useTenants } from '@modules/tenant/tenant-api';
import { usePublicWorkCategories } from '@modules/work-category/work-category-api';
import { useWorkTbpps } from '@modules/work-tbpp/work-tbpp-api';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

export const useColumns = () => {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const intl = useIntl();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  const {
    columnName,
    columnOrder,
    columnCreated,
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  } = DictionaryEvidence.useDictionaryColumns<Work>();

  return [
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    columnOrder,
    columnName,
    {
      datakey: 'category',
      sortkey: 'category.name',
      filterkey: 'category.id',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Typ vedenia',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        usePublicWorkCategories
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'units',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__UNITS',
        defaultMessage: 'Jednotky práce',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'tbpp',
      sortkey: 'tbpp.name',
      filterkey: 'tbpp.id',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__TBPP',
        defaultMessage: 'TBPP',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useWorkTbpps),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
      visible: showTBPP,
    },
    columnCreated,
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  ];
};
