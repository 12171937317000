import { ContentUnavailablePanel } from '@components/content-unavailable-panel/content-unavailable-panel';
import { PersonField } from '@composite/person-collection/person-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { AssignmentType } from '@enums';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PERSON_ASSSIGNMENT } from '../primary-journal-hooked-sources';

export function PersonAssignmentFields() {
  const {
    source: { data },
    mode,
    isLocked,
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  const personAssignment = hookedSources[PERSON_ASSSIGNMENT]?.source;

  return isPrimaryJournalAssigned(data?.id) ? (
    <>
      {data?.id && (
        <PersonField
          source={personAssignment}
          disabled={mode !== DetailMode.VIEW || isLocked}
          initialValues={{
            type: AssignmentType.PRIMARY_JOURNAL,
            journal: { id: data?.id },
          }}
        />
      )}
    </>
  ) : (
    <ContentUnavailablePanel
      title={
        <FormattedMessage
          id="ESD__PRIMARY_JOURAL__DETAIL__ASSIGNMENTS_UNAVAILABLE__TITLE"
          defaultMessage="Chýbajúce oprávnenia"
        />
      }
      reason={
        <FormattedMessage
          id="ESD__PRIMARY_JOURAL__DETAIL__ASSIGNMENTS_UNAVAILABLE__REASON"
          defaultMessage="Nie ste priamym členom aktuálne otvoreného hlavného denníka preto nemáte oprávnenie k zobrazovaniu jeho členov"
        />
      }
    ></ContentUnavailablePanel>
  );
}
