import { useIntl } from 'react-intl';
import React, { useContext } from 'react';
import {
  DomainObject,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl, Permission } from '@enums';
import { useJournalEntryStates } from '@modules/journal-entry/journal-entry-api';
import { Me } from '@models';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return [
    ...(hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_DETAIL)
      ? [
          {
            datakey: 'id',
            name: 'Odkaz',
            width: 80,
            CellComponent: (props: TableFieldCellProps<T>) => (
              <Redirect
                url={EvidenceBrowserUrl.JOURNAL_ENTRY}
                id={props.rowValue!.id}
              />
            ),
          },
        ]
      : []),

    {
      datakey: 'sequenceNumber',
      name: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__COLUMN__SEQUENCE_NUMBER',
        defaultMessage: 'Číslo',
      }),
      width: 75,
    },
    {
      datakey: 'state',
      name: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 100,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useJournalEntryStates
      ),
    },
    /*    {
      datakey: 'comment',
      name: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__COLUMN__COMMENT',
        defaultMessage: 'Komentár',
      }),
      width: 200,
    }, */
    {
      datakey: 'createdBy.name',
      name: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__COLUMN__AUTHOR',
        defaultMessage: 'Autor',
      }),
      width: 200,
    },
    {
      datakey: 'validityDate',
      name: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__COLUMN__VALIDITY_DATE',
        defaultMessage: 'Dátum platnosti',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}
