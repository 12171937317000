/**
 * Vlastník permissions
 */
export enum PermissionTenant {
  /**
   * Prehľad vlastníkou
   */
  TENANT_LIST = 'TENANT_LIST',

  /**
   * Detail vlastníka
   */
  TENANT_DETAIL = 'TENANT_DETAIL',

  /**
   * Editácia vlastníka
   */
  TENANT_UPDATE = 'TENANT_UPDATE',
}
