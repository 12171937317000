import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { SignatureType } from '@enums';

export function useSignatureTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: SignatureType.TIMESTAMP, name: 'Časová pečiatka' },
    { id: SignatureType.KEP, name: 'KEP' },
    { id: SignatureType.STYLUS, name: 'Stylus' },
  ]);
}
