import {
  DetailContext,
  DetailHandle,
  DictionaryAutocomplete,
  SnackbarContext,
  SnackbarVariant,
  useStaticListSource,
} from '@eas/common-web';
import { EvidenceApiUrl, PrimaryJournalState } from '@enums';
import { PrimaryJournal } from '@models';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { KEP_PRIMARY_JOURNAL_ID } from './dialog-actions/sign-primary-journal-kep-hook';

export function usePrimaryJournalState() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: PrimaryJournalState.CREATED, name: 'Vytvorený' },
    { id: PrimaryJournalState.OPENED, name: 'Otvorený' },
    { id: PrimaryJournalState.CLOSED, name: 'Uzavretý' },
    { id: PrimaryJournalState.SIGNED, name: 'Podpísaný' },
    { id: PrimaryJournalState.REOPENED, name: 'Znovu otvorený' },
    { id: PrimaryJournalState.CANCELLED, name: 'Zrušený' },
  ]);
}

export const useKepSignature = () => {
  const {
    source: { data },
    isExisting,
    refreshAll,
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const sessionId = searchParams.get('sessionId');
  const status = searchParams.get('status');
  const history = useHistory();

  const getKepSignature = useCallback(
    (sessionId, signSuccess) =>
      fetch(`${EvidenceApiUrl.PRIMARY_JOURNAL}/${data?.id}/kep/get`, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          sessionId,
          signSuccess,
          checkCertInfo: true,
        }),
      }),
    [data?.id]
  );

  useEffect(() => {
    const finishSignature = async (signSuccess: boolean) => {
      try {
        const res = await getKepSignature(sessionId, signSuccess);

        if (res.ok) {
          signSuccess
            ? showSnackbar(
                'Dokument bol úspešne podpísaný',
                SnackbarVariant.SUCCESS,
                false
              )
            : showSnackbar(
                'Dokument sa nepodarilo podpísať',
                SnackbarVariant.WARNING,
                false
              );

          searchParams.delete('status');
          searchParams.delete('sessionId');
          history.replace({ search: searchParams.toString() });

          localStorage.removeItem(KEP_PRIMARY_JOURNAL_ID);

          signSuccess && refreshAll();
        } else {
          const err = res.json();
          throw err;
        }
      } catch (e) {
        searchParams.delete('status');
        searchParams.delete('sessionId');
        history.replace({ search: searchParams.toString() });

        localStorage.removeItem(KEP_PRIMARY_JOURNAL_ID);

        showSnackbar(
          'Pri dokončovaní podpisu sa niečo pokazilo',
          SnackbarVariant.ERROR,
          false
        );
      }
    };

    console.log('location in primary ===> ', window.location);
    console.log('sessionId ===>', sessionId);
    console.log('status ===>', status);

    if (sessionId && status) finishSignature(status === 'SignSuccess');
  }, [
    showSnackbar,
    getKepSignature,
    isExisting,
    refreshAll,
    sessionId,
    status,
    history,
    searchParams,
  ]);
};
