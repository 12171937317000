import {
  abortableFetch,
  DomainObject,
  useEventCallback,
} from '@eas/common-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CollectionToolbarAction } from '../custom-collection-toolbar';

export function useDeleteCollectionDialog(
  getUrl: (id: string, collectionId: string) => string
) {
  const callApi = useEventCallback((id: string, collectionId: string) => {
    return abortableFetch(`${getUrl(id, collectionId)}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}

export function resolveDeleteAction<T extends DomainObject>(
  props: CollectionToolbarAction<typeof useDeleteCollectionDialog, T>
) {
  return {
    hide: false,
    useApi: useDeleteCollectionDialog,
    label: (
      <FormattedMessage
        id="EAS__COLLECTION__DELETE_LABEL"
        defaultMessage="Smazat"
      />
    ),
    title: (
      <FormattedMessage
        id="EAS__COLLECTION__DELETE_TITLE"
        defaultMessage="Pozor"
      />
    ),
    text: (
      <FormattedMessage
        id="EAS__COLLECTION__DELETE_TEXT"
        defaultMessage="Opravdu chcete smazat položku?"
      />
    ),
    ...props,
  };
}
