import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiFilterOperation, FormAutocomplete } from '@eas/common-web';
import { usePersonAssignedList, usePersons } from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function PersonAssignDialog({
  supplierId,
  primaryJournalId,
  partialJournalId,
}: any) {
  const { result } = usePersonAssignedList({
    skip: false,
    supplierId,
    primaryJournalId,
    partialJournalId,
  });

  const allPeopleInAssignmentListIds = (result?.items ?? []).map(
    (r) => r.person.id
  );

  const persons = usePersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allPeopleInAssignmentListIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <FormAutocomplete
        name="person"
        source={persons}
        labelMapper={personLabelMapper}
        label={
          <FormattedMessage
            id="ESD__PERSON__COLLECTION__FIELD_LABEL__PERSON"
            defaultMessage="Osoba"
          />
        }
        required
      />
    </>
  );
}
