import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CollectionField,
  Filter,
  ScrollableSource,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './work-done-columns';
import { useFilterFields } from './work-done-filter-fields';
import { useValidationSchema } from './work-done-schema';
import { WorkDoneCreateDialog } from './dialogs/work-done-create-dialog';
import { Me, WorkDone } from '@models';
import exceptions from 'src/enums/messages/exception';

interface WorkDoneFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<WorkDone>>> {
  refFilters?: Filter[];
  currentJournalId: string;
  source: ScrollableSource<WorkDone>;
}

export function WorkDoneField({
  currentJournalId,
  source,
  ...overrides
}: WorkDoneFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__WORK_DONE"
          defaultMessage="Vykonaná práca"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/workdone',
        Dialog: WorkDoneCreateDialog,
        initValues: () => ({
          journalEntry: { id: currentJournalId },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
        Dialog: WorkDoneCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 600,
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      filters={filters}
      exceptions={exceptions}
      {...overrides}
    />
  );
}
