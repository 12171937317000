/**
 * Browser urls
 */
export enum EvidenceBrowserUrl {
  HOME = '/',
  ADMIN_SOAP_MESSAGES = '/soap-messages',
  ALOG = '/alog',
  APP_SETTINGS = '/app-settings',
  DATA_SOURCE = '/data-source',
  EXPORT_REQUESTS = '/export-requests',
  EXPORT_TEMPLATES = '/export-templates',
  INTERNAL_SETTINGS = '/settings',
  SEQUENCES = '/sequences',
  SCHEDULE_JOBS = '/schedule-jobs',
  SCHEDULE_RUNS = '/schedule-runs',

  MECHANISM_CATEGORY = '/mechanism-categories',
  MECHANISMS = '/mechanisms',
  WORK = '/work',
  WORK_CATEGORY = '/work-categories',
  TBPP = '/work-tbpp',
  PROFESSION = '/profession',
  WORK_GROUP = '/person-work-group',
  TENANTS = '/tenants',
  ABSENCE = '/absences',

  ROLES = '/roles',
  USERS = '/users',
  PRIMARY_JOURNAL = '/primary-journal',
  PARTIAL_JOURNAL = '/partial-journal',
  JOURNAL_ENTRY = '/journal-entry',
  SUPPLIER = '/supplier',
  PERSON = '/person',
}

export enum EvidenceApiUrl {
  INTERNAL_SETTINGS = '/api/esd/settings',
  LOGIN = '/api/esd/login',

  PRIMARY_JOURNAL = '/api/esd/journal/primary',
  PARTIAL_JOURNAL = '/api/esd/journal/partial',
  JOURNAL_ENTRY = '/api/esd/journal/entry',
  SUPPLIER = '/api/esd/supplier',
  MECHANISM_CATEGORY = '/api/esd/mechanism/category',
  MECHANISMS = '/api/esd/mechanism',
  WORK = '/api/esd/work',
  WORK_CATEGORY = '/api/esd/work/category',
  TBPP = '/api/esd/work/tbpp',
  PROFESSION = '/api/esd/profession',
  WORK_GROUP = '/api/esd/workgroup',
  TENANTS = '/api/esd/tenant',
  ABSENCE = '/api/esd/absence',

  PERMISSION = '/api/esd/public/permission',
  ROLES = '/api/esd/role',
  USERS = '/api/esd/user',
  PERSON = '/api/esd/person',
  CONTEXT = '/api/esd/context',
  CONTEXT_SWITCH = '/api/esd/context/switch',
  ATTACHMENT = '/api/esd/attachment',

  NOTIFICATION_RECEIVED = '/api/esd/notification/received',
  SYSTEM_VERSION = '/api/esd/system/info',
}
