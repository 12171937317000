import { DictionaryEvidence, FormTextField } from '@eas/common-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function TBPPCreateDialog() {
  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <DictionaryEvidence.FieldName />
      <FormTextField
        name="externalId"
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_TBPP__DETAIL__FIELD_LABEL__EXTERNAL_ID"
            defaultMessage="Externý identifikátor"
          />
        }
      />
      <FormTextField
        name="code"
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_TBPP__DETAIL__FIELD_LABEL__CODE"
            defaultMessage="Číslo TBPP"
          />
        }
      />
    </>
  );
}
