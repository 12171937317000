import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { MechanismCategory } from '@models';

export function useMechanismCategories(params?: Params) {
  return useAutocompleteSource<MechanismCategory>({
    url: `${EvidenceApiUrl.MECHANISM_CATEGORY}/autocomplete`,
    params: params,
  });
}

export function usePublicMechanismCategories(params?: Params) {
  return useAutocompleteSource<MechanismCategory>({
    url: `${EvidenceApiUrl.MECHANISM_CATEGORY}/autocomplete/public`,
    params: params,
  });
}

export function useSupplierMechanismCategories(params?: Params) {
  return useAutocompleteSource<MechanismCategory>({
    url: `${EvidenceApiUrl.MECHANISM_CATEGORY}/autocomplete/supplier`,
    params: params,
  });
}
