/**
 * Typ dielčieho denníku
 */
export enum PartialJournalType {
  /**
   * Elektropráce
   */
  EP = 'EP',

  /**
   * Zemné práce
   */
  ZP = 'ZP',

  /**
   * Ostatné
   */
  OTHER = 'OTHER',
}
