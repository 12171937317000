import { useIntl } from 'react-intl';
import {
  DatedEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { User } from '@models';
import { usePersons } from '@modules/person/person-api';
import {
  personColumnLabelMapper,
  personLabelMapper,
} from '@utils/label-mapper';
import { useRoles } from '@modules/roles/role-api';

export function useColumns(): TableColumn<User>[] {
  const intl = useIntl();
  const { datedColumns } = DatedEvidence.useDatedColumns<User>();

  return [
    {
      datakey: 'username',
      name: intl.formatMessage({
        id: 'ESD__USERS__COLUMN__USERNAME',
        defaultMessage: 'Prihlasovacie meno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'person',
      sortkey: 'person.name',
      filterkey: 'person.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS__TABLE__COLUMN__PERSON',
        defaultMessage: 'Osoba',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: personColumnLabelMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        usePersons,
        personLabelMapper
      ),
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'role',
      sortkey: 'role.name',
      filterkey: 'role.id',
      name: intl.formatMessage({
        id: 'ESD__USERS__COLUMN__ROLE',
        defaultMessage: 'Rola',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useRoles),
      sortable: true,
      filterable: true,
    },

    ...datedColumns,
  ];
}
