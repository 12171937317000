import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  Tabs,
  TabsConfig,
  UserContext,
} from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { OverviewFields } from './fields/overview';
import { SupplierMechanismsFields } from './fields/mechanisms/mechanisms';
import { SupplierProfessionsFields } from './fields/professions/professions';
import { SupplierWorkFields } from './fields/work/work';
import { SupplierAbsenceFields } from './fields/absence/absence';
import { SupplierTBPPFields } from './fields/tbpp/tbpp';
import { Me, Supplier } from '@models';
import { TenantCode } from '@enums';

export function Fields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data, loading },
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const isCorrectSupplier = data && data?.id === user?.supplier?.id;

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'OVERVIEW',
      validationKeys: [],
      content: <OverviewFields />,
    },

    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__SUPPLIER_MECHANISMS"
          defaultMessage="Mechanizmy"
        />
      ),
      key: 'SUPPLIER_MECHANISMS',
      validationKeys: [],
      content:
        isCorrectSupplier && !loading ? <SupplierMechanismsFields /> : <></>,
    },
    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__SUPPLIER_PROFESSIONS"
          defaultMessage="Pracovné pozície"
        />
      ),
      key: 'SUPPLIER_PROFESSIONS',
      validationKeys: [],
      content:
        isCorrectSupplier && !loading ? <SupplierProfessionsFields /> : <></>,
    },
    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__SUPPLIER_WORK"
          defaultMessage="Práce"
        />
      ),
      key: 'SUPPLIER_WORK',
      validationKeys: [],
      content: isCorrectSupplier && !loading ? <SupplierWorkFields /> : <></>,
    },
    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__SUPPLIER_ABSENCE"
          defaultMessage="Neprítomnosti"
        />
      ),
      key: 'SUPPLIER_ABSENCE',
      validationKeys: [],
      content:
        isCorrectSupplier && !loading ? <SupplierAbsenceFields /> : <></>,
    },
    ...(user?.tenant?.code === TenantCode.VSD
      ? [
          {
            label: (
              <FormattedMessage
                id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__SUPPLIER_TBPP"
                defaultMessage="TBPP"
              />
            ),
            key: 'SUPPLIER_TBPP',
            validationKeys: [],
            content:
              isCorrectSupplier && !loading ? <SupplierTBPPFields /> : <></>,
          },
        ]
      : []),
  ];
  return <Tabs config={config} />;
}
