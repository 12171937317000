import { useAutocompleteSource, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '../../enums';
import { PermissionGroup, Role } from '../../models';

export function useRoles() {
  return useAutocompleteSource<Role>({
    url: `${EvidenceApiUrl.ROLES}/autocomplete`,
  });
}

export function usePermission() {
  const [permissionGroups] = useFetch<PermissionGroup[]>(
    `${EvidenceApiUrl.PERMISSION}/list/grouped`,
    { method: 'GET' }
  );

  return permissionGroups;
}
