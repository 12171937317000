import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Work } from '@models';

export function useWorks(params?: Params) {
  return useAutocompleteSource<Work>({
    url: `${EvidenceApiUrl.WORK}/autocomplete`,
    params,
  });
}
