import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  listItemsFactory,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me, TBPP } from '@models';
import { useEvidencePermission } from '@utils/permission-hook';
import { useIntl } from 'react-intl';
import { useColumns } from './work-tbpp-columns';
import { Fields } from './work-tbpp-fields';
import { useValidationSchema } from './work-tbpp-schema';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { publicDictionaryListItems } from '@utils/functions';
import { v4 as uuidv4 } from 'uuid';
import { TableToolbarImportButton } from '@components/table-toolbar-import-button/table-toolbar-import-button';
import { handleApiErrors } from '@utils/error-handler';
import { regionApiErrors } from 'src/enums/messages/api-errors';

export const WorkTbpps = () => {
  const intl = useIntl();
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.TBPP.TBPP_CREATE],
      EDIT: [Permission.TBPP.TBPP_UPDATE],
      REMOVE: [Permission.TBPP.TBPP_DELETE],
      REFRESH: [Permission.TBPP.TBPP_DETAIL],
      SHARE: [Permission.TBPP.TBPP_DETAIL],
      CHECK: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
      SAVE: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
      CLOSE: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
    },
  });

  const defaultTbpp: TBPP = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<TBPP>({
    version: 3,
    identifier: 'TBPP',
    apiProps: {
      url: EvidenceApiUrl.TBPP,
      listItems: listItemsFactory<TBPP>({
        listItemsMethod: (url, params) => {
          handleSwitchSupplier();
          return publicDictionaryListItems(url, params);
        },
      }),
      ...handleApiErrors(regionApiErrors.WORK_TBPP, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__TBPP__TABLE__TITLE',
        defaultMessage: 'Práca - TBPP',
      }),
      showReportButton: false,
      toolbarProps: {
        after: hasPermission(Permission.TBPP.TBPP_IMPORT) && (
          <TableToolbarImportButton />
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__TBPP__DETAIL__TITLE',
          defaultMessage: 'Správa TBPP',
        }),
        disableBtn: (button) =>
          (['NEW', 'EDIT'].includes(button) && !!user?.supplier) ||
          (button === 'NEW' && !user?.tenant),
        showBtn: permissions,
      },
      initNewItem: () => defaultTbpp,
    },
  });

  return <Evidence {...evidence} />;
};
