import { abortableFetch, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';

export function useDeleteSignatureAttachment() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (attachmentId: string, signatureId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/${attachmentId}/sign/${signatureId}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'DELETE',
      }
    );
  };

  const showButton = hasPermission(
    Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_SIGN
  );

  return { callApi, showButton };
}
