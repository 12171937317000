import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const Common: MessageType<'SUCCESS' | 'UNEXPECTED_ERROR' | 'VALIDATION_ERROR'> =
  {
    SUCCESS: ['Akcia bola úspešne vykonaná', SnackbarVariant.SUCCESS],
    UNEXPECTED_ERROR: [
      'Došlo k neočakávanej chybe, kontaktujte administrátora.',
      SnackbarVariant.ERROR,
    ],
    VALIDATION_ERROR: [
      'Vo formulári sa nachádzajú chyby',
      SnackbarVariant.ERROR,
    ],
  };

export default Common;
