import { EvidenceApiUrl } from '@enums';

export const getVsdConfig = () => {
  return fetch(`${EvidenceApiUrl.LOGIN}/oauth/vsd/web/config`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};
export const getZsdConfig = () => {
  return fetch(`${EvidenceApiUrl.LOGIN}/oauth/zsd/config`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};
