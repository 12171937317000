import React from 'react';
import { AttachmentsField } from '@composite/attachments-collection/attachments-field';

export function JournalEntryAttachmentsFields() {
  return (
    <>
      <AttachmentsField />
    </>
  );
}
