import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { EvidenceApiUrl, TenantCode } from '@enums';
import { Me, Supplier } from '@models';
import { stubTrue } from 'lodash';
import { useContext } from 'react';

export const SUPPLIER_MECHANISM_CATEGORY_DICTIONARY =
  'SUPPLIER_MECHANISM_CATEGORY_DICTIONARY';
export const SUPPLIER_MECHANISM_DICTIONARY = 'SUPPLIER_MECHANISM_DICTIONARY';
export const SUPPLIER_PROFESSION_DICTIONARY = 'SUPPLIER_PROFESSION_DICTIONARY';
export const SUPPLIER_WORK_CATEGORY_DICTIONARY =
  'SUPPLIER_WORK_CATEGORY_DICTIONARY';
export const SUPPLIER_WORK_DICTIONARY = 'SUPPLIER_WORK_DICTIONARY';
export const SUPPLIER_ABSENCE_DICTIONARY = 'SUPPLIER_ABSENCE_DICTIONARY';
export const SUPPLIER_TBPP_DICTIONARY = 'SUPPLIER_TBPP_DICTIONARY';
//export const SUPPLIER_UNIT_DICTIONARY = 'SUPPLIER_UNIT_DICTIONARY';
export const SUPPLIER_PERSON_ASSIGNMENT = 'SUPPLIER_PERSON_ASSIGNMENT';

const useSupplierMechanismCategorySource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_MECHANISM_CATEGORY_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.MECHANISM_CATEGORY}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierMechanismsSource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_MECHANISM_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.MECHANISMS}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierProfessionsSource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_PROFESSION_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.PROFESSION}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierWorkCategorySource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_WORK_CATEGORY_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.WORK_CATEGORY}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierWorkSource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_WORK_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.WORK}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierAbsencesSource = (): HookedSource<Supplier> => {
  const source = useScrollableSource();

  return {
    [SUPPLIER_ABSENCE_DICTIONARY]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.ABSENCE}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierTBPPSource = (): HookedSource<Supplier> => {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_TBPP_DICTIONARY]: {
      source,
      shouldLoad: () => user?.tenant?.code === TenantCode.VSD,
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.TBPP}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

function usePersonHookedSource(): HookedSource<Supplier> {
  const source = useScrollableSource();

  return {
    [SUPPLIER_PERSON_ASSIGNMENT]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.PERSON}/assignment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'supplier.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export const useSupplierDictionaryHookedSources = () => {
  const supplierMechanismCategories = useSupplierMechanismCategorySource();
  const supplierMechanisms = useSupplierMechanismsSource();

  const supplierProfessions = useSupplierProfessionsSource();

  const supplierWorkCategories = useSupplierWorkCategorySource();
  const supplierWork = useSupplierWorkSource();

  const supplierAbsences = useSupplierAbsencesSource();
  const supplierTBPP = useSupplierTBPPSource();

  const supplierPersons = usePersonHookedSource();

  return {
    ...supplierMechanismCategories,
    ...supplierMechanisms,
    ...supplierProfessions,
    ...supplierWorkCategories,
    ...supplierWork,
    ...supplierAbsences,
    ...supplierTBPP,
    // ...supplierUnits,
    ...supplierPersons,
  };
};
