import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { Me, PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useContext } from 'react';

export function useClosePrimaryDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const callApi = (journalId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.PRIMARY_JOURNAL}/${journalId}/close`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    );
  };

  const showButton =
    (data?.state === PrimaryJournalState.OPENED ||
      data?.state === PrimaryJournalState.REOPENED) &&
    hasPermission(Permission.PrimaryJournal.PRIMARY_JOURNAL_CLOSE) &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton };
}
