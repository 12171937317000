import { BozpTableFieldToolbar } from '@modules/journal-entry/bozp/bozp-table-toolbar';
import SignaturesTableField from '@composite/signatures-field/signatures-table-field';
import {
  FormContext,
  FormFileField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { JournalEntry } from '@models';

export function JournalEntryBozpFields() {
  const { setFieldValue } = useContext(FormContext);

  const { bozp } = useFormSelector((values: JournalEntry) => ({
    bozp: values?.bozp,
  }));

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ESD__JOURNAL_ENTRY_TABLE__PANEL_TITLE__BOZP"
          defaultMessage="BOZP"
        />
      }
    >
      <FormFileField
        name="bozp.file"
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__BOZP_FILE"
            defaultMessage="Súbor"
          />
        }
        accept={['.pdf', '.doc', '.docx']}
        notifyChange={() => {
          if (bozp?.signatures?.length) setFieldValue('bozp.signatures', null);

          if (bozp?.description) setFieldValue('bozp.description', null);
        }}
      />

      <FormTextField
        name="bozp.description"
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__BOZP_DESCRIPTION"
            defaultMessage="Popis"
          />
        }
      />

      <SignaturesTableField
        name="bozp.signatures"
        ToolbarComponent={BozpTableFieldToolbar}
      />
    </FormPanel>
  );
}
