import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useCancelDialog } from './dialog-actions/cancel-hook';
import { useStartDialog } from './dialog-actions/start-hook';
import { useActivateDialog } from './dialog-actions/activate-hook';
import { useDeactivateDialog } from './dialog-actions/deactivate-hook';

export function JobsActionbar() {
  const { callApi: startApiCall, showStartButton } = useStartDialog();
  const { callApi: cancelApiCall, showCancelButton } = useCancelDialog();
  const { callApi: activateApiCall, showButton: showActivateButton } =
    useActivateDialog();
  const { callApi: deactivateApiCall, showButton: showDeactivateButton } =
    useDeactivateDialog();

  return (
    <>
      {showStartButton && (
        <DetailActionButton
          promptKey="START"
          buttonLabel="Spustiť"
          dialogText="Skutočne chcete spustiť úlohu?"
          dialogTitle="Upozornenie"
          apiCall={startApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey="STOP"
          buttonLabel="Zastaviť"
          dialogTitle="Upozornenie"
          dialogText="Zastavenie bežiacej úlohy môže spôsobiť inkonzistenciu dát. Skutočne chcete zastaviť bežiacu úlohu?"
          apiCall={cancelApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
        />
      )}

      {showActivateButton && (
        <PrimaryDetailActionbarButton
          label="Aktivovať"
          onClick={activateApiCall}
        />
      )}
      {showDeactivateButton && (
        <SecondaryDetailActionbarButton
          label="Zneplatniť"
          onClick={deactivateApiCall}
        />
      )}
    </>
  );
}
