import React from 'react';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Me, Supplier, TBPP } from '@models';
import { SUPPLIER_TBPP_DICTIONARY } from '@modules/suppliers/supplier-hooked-sources';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useValidationSchema as useTBPPSchema } from '@modules/work-tbpp/work-tbpp-schema';
import { TBPPCreateDialog } from './dialogs/tbpp-create-dialog';
import { useColumns } from './tbpp-columns';

export function SupplierTBPPFields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const supplierId = data?.id || '';
  const tbppSource = hookedSources[SUPPLIER_TBPP_DICTIONARY]?.source;
  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;

  return (
    <>
      {data && <SupplierDictionaryActionBar sourceUrl={EvidenceApiUrl.TBPP} />}
      <SupplierDictionaryField<TBPP>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL_TBPP__TITLE"
            defaultMessage="Dodávateľský číselník - TBPP"
          />
        }
        sourceUrl={EvidenceApiUrl.TBPP}
        source={tbppSource}
        disabled={disabled}
        useColumns={useColumns}
        createDialog={TBPPCreateDialog}
        useSchema={useTBPPSchema}
      />
    </>
  );
}
