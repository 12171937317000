import React from 'react';
import { CollectionField, Filter, ScrollableSource } from '@eas/common-web';
import { PersonAssignmentType } from '@models';
import { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useColumns } from './supplier-assignments-columns';
import { EvidenceApiUrl } from '@enums';
import { AssignSupplierDialog } from './dialogs/assign-supplier-dialog';
import { useValidationSchema } from './supplier-assignments-schema';

export type PersonSupplierAssignments = Omit<
  PersonAssignmentType,
  'primaryJournal' | 'partialJournal'
>;

interface SupplierAssignmentsFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<PersonSupplierAssignments>>
  > {
  refFilters?: Filter[];
  source: ScrollableSource<PersonSupplierAssignments>;
  initialValues: PersonSupplierAssignments;
}

export function SupplierAssignmentsField({
  source,
  initialValues,
  ...overrides
}: SupplierAssignmentsFieldProps) {
  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__SUPPLIER_ASSIGNMENTS_COLLECTION"
          defaultMessage="Priradený dodávatelia"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.PERSON + '/assignment',
        Dialog: () => AssignSupplierDialog(initialValues.person.id),
        initValues: () => initialValues,
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
