import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  SecondaryDetailActionbarButton,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useSupplierDictionaryImport } from './dialog-actions/supplier-dictionary-import-hook';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { useStyles } from './supplier-dictionary-action-bar-styles';
import { FormattedMessage } from 'react-intl';
import { useSupplierDictionaryTakeover } from './dialog-actions/supplier-dictionary-takeover-hook';
import { Me, Supplier } from '@models';
import { FormImportField } from '@components/form-import-field/form-import-field';
import * as Yup from 'yup';

interface SupplierDictionaryActionBarProps {
  sourceUrl: EvidenceApiUrl;
}

function useValidationSchema() {
  return Yup.object<{ file: File }>().shape({
    file: Yup.mixed().nullable().required(),
  });
}

export function SupplierDictionaryActionBar({
  sourceUrl,
}: SupplierDictionaryActionBarProps) {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<Supplier>>(DetailContext);
  const { callApi: callImportApi, showButton: showImportButton } =
    useSupplierDictionaryImport(sourceUrl);

  const { callApi: callTakeoverApi, showButton: showTakeover } =
    useSupplierDictionaryTakeover(sourceUrl);

  const classes = useStyles();

  const validationSchema = useValidationSchema();

  const disableEdit = data?.id !== user?.supplier?.id;

  return (
    <>
      {(showImportButton || showTakeover) && (
        <div className={classes.actionBar}>
          {showTakeover && (
            <DetailActionButton
              promptKey="TAKEOVER"
              buttonLabel={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__BUTTON_LABEL"
                  defaultMessage="Prevziať"
                />
              }
              dialogTitle={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__DIALOG_TITLE"
                  defaultMessage="Upozornenie"
                />
              }
              dialogText={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__TAKEOVER__DIALOG_TEXT"
                  defaultMessage="Obsah zdieľaného číselníka bude skopírovaný do dodávateľského. Duplicitné záznamy sa vytvárať nebudú."
                />
              }
              apiCall={callTakeoverApi}
              ButtonComponent={SecondaryDetailActionbarButton}
              buttonDisabled={disableEdit}
            />
          )}
          {/*   <ImportActionButton
            baseImportUrl={sourceUrl}
            disabled={disableEdit}
          /> */}
          {showImportButton && (
            <DetailActionButton
              promptKey={`${sourceUrl}_IMPORT`}
              buttonLabel={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__BUTTON_LABEL"
                  defaultMessage="Import"
                />
              }
              dialogTitle={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__DIALOG_TITLE"
                  defaultMessage="Import súboru CSV"
                />
              }
              dialogText={
                <FormattedMessage
                  id="ESD__SUPPLIER__DETAIL__DICTIONARY_TAB__IMPORT__DIALOG_TEXT"
                  defaultMessage={`Pre import dát do číselníka, vložte súbor.`}
                />
              }
              apiCall={callImportApi}
              ButtonComponent={TertiaryDetailActionbarButton}
              buttonDisabled={disableEdit}
              FormFields={() => (
                <>
                  <FormImportField
                    name="file"
                    label={
                      <FormattedMessage
                        id="ESD____TABLE_TOOLBAR_DIALOG__FIELD_LABEL__FILE"
                        defaultMessage="Súbor CSV"
                      />
                    }
                    acceptedFormats={['.csv']}
                    required
                  />
                </>
              )}
              formValidationSchema={validationSchema}
            />
          )}
        </div>
      )}
    </>
  );
}
