import React from 'react';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
} from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { useTenants } from '@modules/tenant/tenant-api';

export const Fields = () => {
  const tenants = useTenants();

  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormAutocomplete
        name="tenant"
        source={tenants}
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__TENANT"
            defaultMessage="Vlastník"
          />
        }
        disabled
      />

      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
};
