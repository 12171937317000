import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { ExportTag } from '../../enums';

export function useExportTag() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ExportTag.INTERNAL, name: 'Interní' },

    { id: ExportTag.EXPORT_TEMPLATES_GRID, name: 'Prehľad šablón exportu' },
    { id: ExportTag.EXPORT_TEMPLATES_DETAIL, name: 'Detail šablóny exportu' },

    { id: ExportTag.EXPORT_REQUESTS_GRID, name: 'Prehľad fronty exportov' },
    {
      id: ExportTag.EXPORT_REQUESTS_DETAIL,
      name: 'Detail záznamu fronty exportov',
    },
  ]);
}

/* export function useReportPermission() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: Permission.Default.ADMINISTRATOR, name: 'Administrátor' },
    { id: Permission.Default.INTERNAL, name: 'Operátor' },
  ]);
}
 */
