import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  listItemsFactory,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useIntl } from 'react-intl';
import { Me, MechanismCategory } from '@models';
import { useColumns } from './mechanism-category-columns';
import { Fields } from './mechanism-category-fields';
import { useValidationSchema } from './mechanism-category-schema';
import { publicDictionaryListItems } from '@utils/functions';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { v4 as uuidv4 } from 'uuid';
import { TableToolbarImportButton } from '@components/table-toolbar-import-button/table-toolbar-import-button';
import { handleApiErrors } from '@utils/error-handler';
import { regionApiErrors } from 'src/enums/messages/api-errors';

export function MechanismCategories() {
  const intl = useIntl();
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const { showSnackbar } = useContext(SnackbarContext);

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE],
      EDIT: [Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE],
      REMOVE: [Permission.MechanismCategory.MECHANISM_CATEGORY_DELETE],
      REFRESH: [Permission.MechanismCategory.MECHANISM_CATEGORY_DETAIL],
      SHARE: [Permission.MechanismCategory.MECHANISM_CATEGORY_DETAIL],
      CHECK: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
      SAVE: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
      CLOSE: [
        Permission.MechanismCategory.MECHANISM_CATEGORY_CREATE,
        Permission.MechanismCategory.MECHANISM_CATEGORY_UPDATE,
      ],
    },
  });

  const defaultMechanismCategory: MechanismCategory = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<MechanismCategory>({
    version: 3,
    identifier: 'MECHANISM_CATEGORY',
    apiProps: {
      url: EvidenceApiUrl.MECHANISM_CATEGORY,
      listItems: listItemsFactory<MechanismCategory>({
        listItemsMethod: (url, params) => {
          handleSwitchSupplier();
          return publicDictionaryListItems(url, params);
        },
      }),
      ...handleApiErrors(regionApiErrors.MECHANISM_CATEGORY, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__MECHANISM_CATEGORY__TABLE__TITLE',
        defaultMessage: 'Kategória mechanizmu',
      }),
      showReportButton: false,
      toolbarProps: {
        after: hasPermission(
          Permission.MechanismCategory.MECHANISM_CATEGORY_IMPORT
        ) && <TableToolbarImportButton />,
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__MECHANISM_CATEGORY__DETAIL__TITLE',
          defaultMessage: 'Správa kategórií mechanizmov',
        }),
        disableBtn: (button) =>
          (['NEW', 'EDIT'].includes(button) && !!user?.supplier) ||
          (button === 'NEW' && !user?.tenant),
        showBtn: permissions,
      },
      initNewItem: () => defaultMechanismCategory,
    },
  });

  return <Evidence {...evidence} />;
}
