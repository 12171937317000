import React from 'react';
import { DictionaryEvidence, FormTextField } from '@eas/common-web';
import { FormattedMessage } from 'react-intl';

export function DefaultSupplierDictionaryCreateDialog() {
  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_DICTIONARY__COLLECTION__FIELD_LABEL__NAME"
            defaultMessage="Názov"
          />
        }
        required
      />
    </>
  );
}
