import clsx from 'clsx';
import React, { ReactNode, useContext } from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import {
  FileField,
  FilesContext,
  FormContext,
  FormFieldContext,
  useFormSelector,
} from '@eas/common-web';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    margin: 'auto',
  },
  placeholder: {
    backgroundColor: grey[300],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: 35,
      height: 35,
      fill: grey[500],
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

interface FormImageFieldProps {
  name: string;
  label?: ReactNode;
  height?: number;
  after?: ReactNode;
  disabled?: boolean;
  showFileField: boolean;
}

export function FormImageField({
  name,
  // label,
  height = 100,
  after,
  disabled,
  showFileField,
}: FormImageFieldProps) {
  const classes = useStyles();

  const formFieldContext = useContext(FormFieldContext);
  const { getFileUrl } = useContext(FilesContext);
  const { setFieldValue, editing } = useContext(FormContext);

  const { image } = useFormSelector((data: any) => ({
    image: data[name],
  }));

  return (
    /*   <FormCustomField label={label} name={name}> */
    <div className={classes.wrapper}>
      {showFileField && (
        <FileField
          value={image}
          onChange={(value) => setFieldValue(name, value)}
          accept={[
            '.jpg',
            '.JPG',
            '.png',
            '.PNG',
            '.bmp',
            '.BMP',
            '.gif',
            '.GIF',
            '.svg',
            '.SVG',
            '.jpeg',
            '.JPEG',
          ]}
          disabled={formFieldContext.disabled ?? (!editing || disabled)}
        />
      )}
      <div
        className={clsx({ [classes.placeholder]: !image })}
        style={{ height, width: image ? 'fit-content' : height }}
      >
        {image ? (
          <img src={getFileUrl(image.id)} className={classes.image} />
        ) : (
          <ImageIcon />
        )}
      </div>
      {after}
    </div>
    /*  </FormCustomField> */
  );
}
