/**
 * Region journal entry's mechanism persmissions
 */
export enum PermissionJournalEntryMechanism {
  /**
   * Prehľad mechanizmu denných záznamov
   */
  JOURNAL_ENTRY_MECHANISM_LIST = 'JOURNAL_ENTRY_MECHANISM_LIST',

  /**
   * Detail mechanizmu denného záznamu
   */
  JOURNAL_ENTRY_MECHANISM_DETAIL = 'JOURNAL_ENTRY_MECHANISM_DETAIL',

  /**
   * Pridanie mechanizmu denného záznamu
   */
  JOURNAL_ENTRY_MECHANISM_CREATE = 'JOURNAL_ENTRY_MECHANISM_CREATE',

  /**
   * Editácia mechanizmu denného záznamu
   */
  JOURNAL_ENTRY_MECHANISM_UPDATE = 'JOURNAL_ENTRY_MECHANISM_UPDATE',

  /**
   * Zmazanie mechanizmu denného záznamu
   */
  JOURNAL_ENTRY_MECHANISM_DELETE = 'JOURNAL_ENTRY_MECHANISM_DELETE',
}
