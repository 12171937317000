import {
  ApiFilterOperation,
  Filter,
  ListSource,
  useFetch,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Timesheet } from '@models';

export function useTimesheetList({
  skip,
  query,
  /*   userIds, */
  journalEntryId,
}: {
  skip: boolean;
  journalEntryId: string;
  query?: string;
  /*  userIds?: string[]; */
}) {
  const filters: Filter[] = [
    {
      field: 'journalEntry.id',
      operation: ApiFilterOperation.EQ,
      value: journalEntryId,
    },
  ];

  /*   if (userIds) {
    filters.push({
      field: 'id',
      operation: ApiFilterOperation.IN,
      values: userIds,
    });
  } */

  if (query) {
    filters.push({
      operation: ApiFilterOperation.FTX,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<ListSource<Timesheet>>(
    `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        filters,
        sort: [],
      }),
      skip,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}
