import { DomainObject, TableFieldColumn, UserContext } from '@eas/common-web';
import { Me } from '@models';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const intl = useIntl();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_WORK__TABLE__COLUMN__ORDER',
        defaultMessage: 'Poradie',
      }),
      datakey: 'order',
      sortkey: 'order',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_WORK__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Typ vedenia',
      }),
      datakey: 'category.name',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_WORK__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 200,
    },
    {
      datakey: 'units',
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_WORK__TABLE__COLUMN__UNITS',
        defaultMessage: 'Jednotky práce',
      }),
      width: 200,
    },
    {
      datakey: 'tbpp.name',
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_WORK__TABLE__COLUMN__TBPP',
        defaultMessage: 'TBPP',
      }),
      width: 200,
      visible: showTBPP,
    },
  ];
}
