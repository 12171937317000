import {
  abortableFetch,
  DomainObject,
  useEventCallback,
} from '@eas/common-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CollectionDialog } from '../collection-dialog';
import { useValidationSchema } from '../collection-schema';
import { CollectionToolbarAction } from '../custom-collection-toolbar';

export function useUpdateCollectionDialog<T extends DomainObject>(
  getUrl: (id: string, collectionId: string) => string
) {
  const callApi = useEventCallback(
    (id: string, collectionId: string, formData: T) => {
      return abortableFetch(`${getUrl(id, collectionId)}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      });
    }
  );

  return {
    callApi,
  };
}

export function resolveUpdateAction<T extends DomainObject>(
  props: CollectionToolbarAction<typeof useUpdateCollectionDialog<T>, T>
) {
  return {
    hide: false,
    useApi: useUpdateCollectionDialog,
    label: (
      <FormattedMessage
        id="EAS__COLLECTION__UPDATE_LABEL"
        defaultMessage="Upravit"
      />
    ),
    title: (
      <FormattedMessage
        id="EAS__COLLECTION__UPDATE_TITLE"
        defaultMessage="Úprava"
      />
    ),
    text: (
      <FormattedMessage
        id="EAS__COLLECTION__UPDATE_TEXT"
        defaultMessage="Vyplňte formulář"
      />
    ),
    Dialog: CollectionDialog,
    dialogWidth: 400,
    useSchema: useValidationSchema,
    ...props,
  };
}
