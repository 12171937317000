import { abortableFetch, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useContext } from 'react';

export function useSupplierDictionaryTakeover(sourceUrl: EvidenceApiUrl) {
  const { hasPermission, user } = useContext(UserContext);

  const callApi = () => {
    console.log(user, 'context before takover');
    return abortableFetch(`${sourceUrl}/takeover`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const hasMechanismPermission =
    sourceUrl === EvidenceApiUrl.MECHANISMS &&
    hasPermission(Permission.Mechanism.MECHANISM_TAKEOVER);

  const hasAbsencePermission =
    sourceUrl === EvidenceApiUrl.ABSENCE &&
    hasPermission(Permission.Absence.ABSENCE_TAKEOVER);

  const hasWorkPermission =
    sourceUrl === EvidenceApiUrl.WORK &&
    hasPermission(Permission.Work.WORK_TAKEOVER);

  const hasProfessionPermission =
    sourceUrl === EvidenceApiUrl.PROFESSION &&
    hasPermission(Permission.Profession.PROFESSION_TAKEOVER);

  const hasTbppPermission =
    sourceUrl === EvidenceApiUrl.TBPP &&
    hasPermission(Permission.TBPP.TBPP_TAKEOVER);

  const showButton =
    hasMechanismPermission ||
    hasAbsencePermission ||
    hasWorkPermission ||
    hasProfessionPermission ||
    hasTbppPermission;

  return { callApi, showButton };
}
