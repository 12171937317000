import React from 'react';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Absence, Me, Supplier } from '@models';
import { SUPPLIER_ABSENCE_DICTIONARY } from '@modules/suppliers/supplier-hooked-sources';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

export function SupplierAbsenceFields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const supplierId = data?.id || '';
  const absenceSource = hookedSources[SUPPLIER_ABSENCE_DICTIONARY]?.source;

  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;

  return (
    <>
      {data && (
        <SupplierDictionaryActionBar sourceUrl={EvidenceApiUrl.ABSENCE} />
      )}
      <SupplierDictionaryField<Absence>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__ABSENCE__TITLE"
            defaultMessage="Dodávateľský číselník - Neprítomnosti"
          />
        }
        sourceUrl={EvidenceApiUrl.ABSENCE}
        source={absenceSource}
        disabled={disabled}
      />
    </>
  );
}
