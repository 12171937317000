import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useProfessions } from '@modules/profession/profession-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { PersonType, Supplier } from '@models';
import { SupplierAssignmentsField } from '@composite/supplier-assignments-collection/supplier-assignments-field';
import { PERSON_SUPPLIER_ASSIGNMENT } from './person-hooked-sources';
import { AssignmentType } from '@enums';
import { v4 as uuidv4 } from 'uuid';

export function Fields() {
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<PersonType>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const supplierAssignmentsSource =
    hookedSources[PERSON_SUPPLIER_ASSIGNMENT]?.source;

  const tenant = useFormSelector((value: PersonType) => value.tenant);

  const professionSource = useProfessions();
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné údaje"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Meno"
            />
          }
          required
        />
        <FormTextField
          name="surname"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__SURNAME"
              defaultMessage="Priezvisko"
            />
          }
          required
        />
        <FormTextField
          name="email"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__EMAIL"
              defaultMessage="E-mail"
            />
          }
        />
        {tenant?.code === 'ZSD' && (
          <FormTextField
            name="kid"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__KID"
                defaultMessage="KID"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
        {tenant?.code === 'VSD' && (
          <FormTextField
            name="personalNumber"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__PERSONAL_NUMBER"
                defaultMessage="Osobné číslo"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__WORK_INFO"
            defaultMessage="Pracovné údaje"
          />
        }
      >
        <FormTextField
          name="team"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__TEAM"
              defaultMessage="Tím"
            />
          }
        />
        <FormAutocomplete
          name="profession"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__PROFESSION"
              defaultMessage="Pracovná pozícia"
            />
          }
          source={professionSource}
        />
        <FormAutocomplete
          name="tenant"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          source={tenants}
          disabled={mode === DetailMode.EDIT}
        />
      </FormPanel>

      <SupplierAssignmentsField
        source={supplierAssignmentsSource}
        initialValues={{
          id: uuidv4(),
          type: AssignmentType.SUPPLIER,
          person: { id: data?.id ?? '' },
          supplier: {} as Supplier,
        }}
        defaultExpanded
      />

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
