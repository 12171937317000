import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { WorkCategory } from '@models';

export function useWorkCategories(params?: Params) {
  return useAutocompleteSource<WorkCategory>({
    url: `${EvidenceApiUrl.WORK_CATEGORY}/autocomplete`,
    params,
  });
}

export function usePublicWorkCategories(params?: Params) {
  return useAutocompleteSource<WorkCategory>({
    url: `${EvidenceApiUrl.WORK_CATEGORY}/autocomplete/public`,
    params,
  });
}

export function useSupplierWorkCategories(params?: Params) {
  return useAutocompleteSource<WorkCategory>({
    url: `${EvidenceApiUrl.WORK_CATEGORY}/autocomplete/supplier`,
    params,
  });
}
