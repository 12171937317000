/**
 * Denný záznam - stav
 */
export enum JournalEntryState {
  /**
   * Otvorený
   */
  OPENED = 'OPENED',

  /**
   * Podpísaný
   */
  SIGNED = 'SIGNED',

  /**
   * Uzavretý
   */
  CLOSED = 'CLOSED',
}
