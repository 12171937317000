import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableFilterCells,
} from '@eas/common-web';
import { Profession } from '@models';
import { useTenants } from '@modules/tenant/tenant-api';
import { useIntl } from 'react-intl';

export const useColumns = () => {
  const intl = useIntl();

  const { dictionaryColumns } =
    DictionaryEvidence.useDictionaryColumns<Profession>();

  return [
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISM__TABLE__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    ...dictionaryColumns,
  ];
};
