/**
 * Typ deníku
 */
export enum JournalTypeEnum {
  /**
   * Hlavní deník
   */
  PRIMARY = 'PRIMARY',

  /**
   * Dílčí deník
   */
  PARTIAL = 'PARTIAL',
}
