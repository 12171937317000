import {
  AuthoredObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useIntl } from 'react-intl';

export function useColumns<T extends AuthoredObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'supplier.name',
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_ASSIGNMENTS_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Dodávateľ',
      }),
      width: 300,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'supplier.ico',
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_ASSIGNMENTS_COLLECTION__TABLE__COLUMN__ICO',
        defaultMessage: 'IČO',
      }),
      width: 100,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
