import React from 'react';
import { ApiFilterOperation, FormAutocomplete } from '@eas/common-web';
import { usePersonAssignedSupplierList } from '@modules/person/person-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { FormattedMessage } from 'react-intl';

export function AssignSupplierDialog(personId: string) {
  const { result } = usePersonAssignedSupplierList({
    skip: false,
    personId,
  });

  const allPersonSupplierAssignmentsIds = (result?.items ?? []).map(
    (assignment) => assignment.supplier.id
  );

  const suppliers = useSuppliers({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allPersonSupplierAssignmentsIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <FormAutocomplete
        name="supplier"
        source={suppliers}
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_ASSIGNMENTS_COLLECTION__FIELD_LABEL__SUPPLIER"
            defaultMessage="Dodávateľ"
          />
        }
        required
      />
    </>
  );
}
