import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CollectionField,
  Filter,
  ScrollableSource,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './mechanism-columns';
import { useFilterFields } from './mechanism-filter-fields';
import { useValidationSchema } from './mechanism-schema';
import { MechanismCreateDialog } from './dialogs/mechanism-create-dialog';
import { Me } from '@models';
import exceptions from 'src/enums/messages/exception';

// TODO - Dotáhnout naming - mechanisms, mechanisms type, následně přidat typy!
// Nelze přidávat v entry typ mechanismu, když číselník se jmenuje mechanismus.

interface MechanismFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<any>>> {
  refFilters?: Filter[];
  currentJournalId: string;
  source: ScrollableSource<any>;
}

export function MechanismField({
  currentJournalId,
  source,
  ...overrides
}: MechanismFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__MECHANISM"
          defaultMessage="Mechanizmy"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/mechanism',
        Dialog: MechanismCreateDialog,
        initValues: () => ({
          journalEntry: { id: currentJournalId },
          distance: 0,
          hours: 0,
        }),
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
        Dialog: MechanismCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 650,
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      filters={filters}
      exceptions={exceptions}
      {...overrides}
    />
  );
}
