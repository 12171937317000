import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Title } from '../../components/title/title';

/* function cardFactory(definitionId: string) {
  switch (definitionId) {
    default:
      return undefined;
  }
} */

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 180px)',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cardUniversalValue: {
      fontFamily: 'montserrat',
    },
    cardActionValue: {
      fontFamily: 'montserrat',
    },
  })
);

export function EsdDashboard() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Grid item xs={12}>
            <Title name="Vitajte v portáli aplikácie ESD" />
            {/*  <Dashboard cardFactory={cardFactory} /> */}
          </Grid>
        </Container>
      </div>
    </div>
  );
}
