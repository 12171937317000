import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const exceptions: MessageType<string> = {
  ENTITY_ALREADY_EXIST: [
    'Položka s rovnakým názvom existuje.',
    SnackbarVariant.ERROR,
  ],
};

export default exceptions;
