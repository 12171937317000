import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  AdminProvider,
  AppSettingsContext,
  DetailToolbar2,
  EvidenceDefaultsProvider,
  ExportProvider,
  InternalSettingsProvider,
  Menubar,
  MenubarContext,
  NamedSettingsProvider,
  NavigationContext,
  ReportingProvider,
  ScheduleProvider,
  SplitScreenMode,
  Tooltip,
  useFetch,
  UserContext,
  UserSettingsProvider,
} from '@eas/common-web';
import logo from '@components/menu/logo.svg';
//import { AppSettings } from './models';
import * as Module from './modules';
import {
  AbsenceListPermissions,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  TenantCode,
  JournalEntryListPermissions,
  MechanismCategoryListPermissions,
  MechanismListPermissions,
  PartialJournalListPermissions,
  Permission,
  PrimaryJournalListPermissions,
  ProfessionListPermissions,
  TBPPListPermissions,
  WorkCategoryListPermissions,
  WorkListPermissions,
} from '@enums';
import { useMenuItems } from '@components/menu/menu-items';

import { useExportTag } from './modules/export/export-api';
import { AppSettings } from './models';
import { ProtectedRoute } from '@components/router/protected-route';
import { NotificationButton } from '@components/notification/notification-button';
import { KEP_PRIMARY_JOURNAL_ID } from '@modules/primary-journals/dialog-actions/sign-primary-journal-kep-hook';

export const useStyles = makeStyles({
  logo: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  title: {
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    marginLeft: 10,
  },
  userButton: {
    fontSize: 12,
  },
  subMenuItem: {
    margin: 0,
  },
  subMenuItemIcon: {
    minWidth: 25,
  },
});

interface SystemInfo {
  version?: string;
  time?: string;
  name?: string;
}

export function AppSecure() {
  const classes = useStyles();

  const { user, hasPermission } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { pathname } = useLocation();

  const menuRef = useRef<MenubarContext>(null);

  const [systemInfo] = useFetch<SystemInfo>(EvidenceApiUrl.SYSTEM_VERSION);

  useEffect(() => {
    if (user) {
      const isAdmin = hasPermission(Permission.Default.ADMINISTRATOR);

      // const isOperator = hasPermission(Permission.Default.INTERNAL);

      if (!isAdmin /* && !isOperator */) {
        // logout();
      }

      const KepPrimaryJournal = localStorage.getItem(KEP_PRIMARY_JOURNAL_ID);

      console.log('journalIDStorage ===>', KepPrimaryJournal);
      console.log('searchParams ===>', window.location.search);

      if (KepPrimaryJournal && window.location.search) {
        navigate(
          `${EvidenceBrowserUrl.PRIMARY_JOURNAL}/${KepPrimaryJournal}${window.location.search}`
        );
      }
    }

    if (!user) {
      window.sessionStorage.setItem('redirectTo', pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExportTag = useExportTag();
  const { filteredItems, userBtnActions } = useMenuItems(menuRef);

  const { settings } = useContext<AppSettingsContext<AppSettings>>(
    AppSettingsContext as any
  );

  return (
    <>
      {!user ? (
        <Redirect to={'/login'} />
      ) : (
        <ScheduleProvider
          jobUrl="/api/esd/schedule/jobs"
          runUrl="/api/esd/schedule/runs"
        >
          {window.sessionStorage.removeItem('redirectTo')}
          <ReportingProvider url="/api/esd/reporting">
            <ExportProvider url="/api/esd/export" tags={ExportTag}>
              <UserSettingsProvider url="/api/esd/user-settings">
                <NamedSettingsProvider url="/api/esd/named-settings">
                  <InternalSettingsProvider
                    url={EvidenceApiUrl.INTERNAL_SETTINGS}
                  >
                    <EvidenceDefaultsProvider
                      appName="ESD - Portál"
                      appLogo={logo}
                      enableEmptyDetailContainer={true}
                      ToolbarComponent={DetailToolbar2}
                      defaultTableNamedSettings={false}
                      splitScreen={
                        +settings.evidenceMode ?? SplitScreenMode.SPLIT
                      }
                    >
                      <Menubar
                        ref={menuRef}
                        userBtnActions={userBtnActions}
                        logoUrl="/"
                        logo={<img src={logo} className={classes.logo} />}
                        title={
                          <Tooltip
                            title={`Verzia: ${systemInfo?.version ?? ''}`}
                          >
                            <Typography className={classes.title}>
                              <FormattedMessage
                                id="ESD__APP_TITLE"
                                defaultMessage="ESD - Portál"
                              />
                            </Typography>
                          </Tooltip>
                        }
                        items={filteredItems}
                        classOverrides={{
                          userButton: classes.userButton,
                          subMenuItem: classes.subMenuItem,
                          subMenuItemIcon: classes.subMenuItemIcon,
                        }}
                        beforeUserBtn={<NotificationButton />}
                        displayLogoutBtn={false}
                      >
                        <AdminProvider
                          permission={Permission.Default.ADMINISTRATOR}
                          prefix=""
                          reindexUrl="/api/esd/reindex"
                        />
                        <Switch>
                          <Route
                            path={EvidenceBrowserUrl.HOME}
                            exact
                            component={Module.EsdDashboard}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.PRIMARY_JOURNAL}/:id?`}
                            component={Module.PrimaryJournals}
                            permissions={PrimaryJournalListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.PARTIAL_JOURNAL}/:id?`}
                            component={Module.PartialJournals}
                            permissions={PartialJournalListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.JOURNAL_ENTRY}/:id?`}
                            component={Module.JournalEntries}
                            permissions={JournalEntryListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.MECHANISM_CATEGORY}/:id?`}
                            component={Module.MechanismCategories}
                            permissions={MechanismCategoryListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.MECHANISMS}/:id?`}
                            component={Module.Mechanisms}
                            permissions={MechanismListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.WORK_CATEGORY}/:id?`}
                            component={Module.WorkCategories}
                            permissions={WorkCategoryListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.WORK}/:id?`}
                            component={Module.Works}
                            permissions={WorkListPermissions}
                          />
                          {user?.tenant?.code === TenantCode.VSD && (
                            <ProtectedRoute
                              path={`${EvidenceBrowserUrl.TBPP}/:id?`}
                              component={Module.WorkTbpps}
                              permissions={TBPPListPermissions}
                            />
                          )}
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.PROFESSION}/:id?`}
                            component={Module.Professions}
                            permissions={ProfessionListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.ROLES}/:id?`}
                            component={Module.Roles}
                            permissions={[Permission.Role.ROLE_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.USERS}/:id?`}
                            component={Module.Users}
                            permissions={[Permission.User.USER_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.SUPPLIER}/:id?`}
                            component={Module.Suppliers}
                            permissions={[Permission.Supplier.SUPPLIER_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.PERSON}/:id?`}
                            component={Module.Person}
                            permissions={[Permission.Person.PERSON_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.WORK_GROUP}/:id?`}
                            component={Module.WorkGroups}
                            permissions={[Permission.WorkGroup.WORKGROUP_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.TENANTS}/:id?`}
                            component={Module.Tenants}
                            permissions={[Permission.Tenant.TENANT_LIST]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.ABSENCE}/:id?`}
                            component={Module.Absences}
                            permissions={AbsenceListPermissions}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.ALOG}/:id?`}
                            component={Module.Alog}
                            permissions={[Permission.Default.ADMINISTRATOR]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.EXPORT_REQUESTS}/:id?`}
                            component={Module.ExportRequests}
                            permissions={[Permission.Default.ADMINISTRATOR]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.EXPORT_TEMPLATES}/:id?`}
                            component={Module.ExportTemplates}
                            permissions={[Permission.Default.ADMINISTRATOR]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.SCHEDULE_JOBS}/:id?`}
                            component={Module.ScheduleJobs}
                            permissions={[Permission.Default.SCHEDULE_ALL]}
                          />
                          <ProtectedRoute
                            path={`${EvidenceBrowserUrl.SCHEDULE_RUNS}/:id?`}
                            component={Module.ScheduleRuns}
                            permissions={[Permission.Default.SCHEDULE_ALL]}
                          />
                        </Switch>
                      </Menubar>
                    </EvidenceDefaultsProvider>
                  </InternalSettingsProvider>
                </NamedSettingsProvider>
              </UserSettingsProvider>
            </ExportProvider>
          </ReportingProvider>
        </ScheduleProvider>
      )}
    </>
  );
}
