import {
  abortableFetch,
  DetailContext,
  DetailMode,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';

export function useSignBozpByTimestamp() {
  const {
    source: { data },
    mode,
  } = useContext(DetailContext);

  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = () => {
    return abortableFetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY}/${data?.id}/bozp/sign`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          type: 'TIMESTAMP',
          signedBy: { id: user?.person?.id },
          timestamp: new Date(),
        }),
      }
    );
  };

  const showButton =
    data.bozp?.file &&
    mode === DetailMode.VIEW &&
    hasPermission(Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_SIGN);

  return { callApi, showButton };
}
