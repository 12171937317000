import { UserContext } from '@eas/common-web';
import { Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';

export const useUserAssignments = () => {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);

  const isPrimaryJournalAssigned = (primaryJournalId?: string) => {
    if (primaryJournalId) {
      return (
        !hasPermission(
          Permission.PrimaryJournal.PRIMARY_JOURNAL_READ_ASSIGNED
        ) || !!user?.assignments?.PRIMARY_JOURNAL.includes(primaryJournalId)
      );
    }
    return false;
  };

  return { isPrimaryJournalAssigned };
};
