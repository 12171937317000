import { abortableFetch, DetailContext, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { useContext } from 'react';
import { PartialJournalState } from 'src/enums/partial-journal-state';

export function useCancelPartialJournalDialog() {
  const {
    source: { data },
  } = useContext(DetailContext);

  const { hasPermission } = useContext(UserContext);

  const callApi = (journalId: string, formValues: { reason?: string }) => {
    return abortableFetch(
      /* `${EvidenceApiUrl.PARTIAL_JOURNAL}/${journalId}/cancel`, */
      `${EvidenceApiUrl.PARTIAL_JOURNAL}/${journalId}/cancel/notification`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues.reason,
      }
    );
  };

  const showButton =
    data?.parent?.state !== PrimaryJournalState.CANCELLED &&
    data?.state !== PartialJournalState.CANCELLED &&
    hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE);

  return { callApi, showButton };
}
