import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useSupplierMechanismCategories } from '@modules/mechanism-category/mechanism-category-api';
import { useIntl } from 'react-intl';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_MECHANISMS__TABLE__COLUMN__ORDER',
        defaultMessage: 'Poradie',
      }),
      datakey: 'order',
      sortkey: 'order',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_MECHANISMS__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Kategória mechanizmu',
      }),
      datakey: 'category',
      sortkey: 'category.name',
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useSupplierMechanismCategories,
        multiple: false,
      }),
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_MECHANISMS__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER_MECHANISMS__TABLE__COLUMN__SPZ',
        defaultMessage: 'ŠPZ',
      }),
      datakey: 'licencePlate',
      sortkey: 'licencePlate',
      width: 200,
    },
  ];
}
