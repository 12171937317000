import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import RemoveIcon from '@material-ui/icons/Delete';
import {
  DetailContext,
  PrimaryDetailActionbarButton,
  TableFieldActionButton,
  TableFieldContext,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
  useEventCallback,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSignBozpByTimestamp } from '../dialog-actions/sign-bozp-timestamp-hook';

export const useStyles = makeStyles(() => ({
  tableActions: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 12px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    // marginLeft: -5, // offset first icon left padding
  },
}));

export function BozpTableFieldToolbar({
  selectedIndex,
}: TableFieldToolbarProps) {
  const classes = useStyles();

  const { showRemoveDialog, disabled, disabledRemove, visibleRemove } =
    useContext(TableFieldContext);

  const { refresh } = useContext(DetailContext);

  const handleRemoveClick = useEventCallback(() => {
    if (selectedIndex !== undefined) {
      showRemoveDialog(selectedIndex);
    }
  });

  const { callApi: timestampSignApiCall, showButton: showTimestampButton } =
    useSignBozpByTimestamp();

  return (
    <div className={classes.tableActions}>
      <div className={classes.buttonGroup}>
        <TableFieldToolbarButton
          title={
            <FormattedMessage
              id="EAS_TABLE_FIELD_TOOLBAR_BTN_DELETE"
              defaultMessage="Zmazať"
            />
          }
          onClick={handleRemoveClick}
          IconComponent={RemoveIcon}
          disabled={disabled || disabledRemove || selectedIndex === undefined}
          show={visibleRemove}
        />
        {showTimestampButton && (
          <TableFieldActionButton
            dialogTitle={
              <FormattedMessage
                id="ESD_BOZP_SIGNATURES__TOOLBAR__TIMESTAMP_SIGN__DIALOG_TITLE"
                defaultMessage="Podpísanie BOZP časovou pečiatkou"
              />
            }
            dialogText={
              <FormattedMessage
                id="ESD_BOZP_SIGNATURES__TOOLBAR__TIMESTAMP_SIGN__DIALOG_TEXT"
                defaultMessage="Naozaj chcete podpísať BOZP?"
              />
            }
            buttonLabel={
              <FormattedMessage
                id="ESD_BOZP_SIGNATURES__TOOLBAR__TIMESTAMP_SIGN__BUTTON_LABEL"
                defaultMessage="Podpísanie časovou pečiatkou"
              />
            }
            promptKey="BOZP_TIMESTAMP_SIGN"
            apiCall={timestampSignApiCall}
            ButtonComponent={PrimaryDetailActionbarButton}
            onSuccess={refresh as any}
          />
        )}
      </div>
    </div>
  );
}
