import { PersonField } from '@composite/person-collection/person-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { AssignmentType } from '@enums';
import { PartialJournal } from '@models';
import React, { useContext } from 'react';
import { PERSON_ASSSIGNMENT } from '../partial-journal-hooked-sources';

export function PersonAssignmentFields() {
  const {
    source: { data },
    mode,
    isLocked,
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const personAssignment = hookedSources[PERSON_ASSSIGNMENT]?.source;

  return (
    <>
      {data?.id && (
        <PersonField
          source={personAssignment}
          disabled={mode !== DetailMode.VIEW || isLocked}
          initialValues={{
            type: AssignmentType.PARTIAL_JOURNAL,
            journal: { id: data.id },
          }}
        />
      )}
    </>
  );
}
