/**
 * Dictionary - Work category permissions
 */
export enum PermissionWork {
  /**
   * Prehľad prác
   */
  WORK_LIST = 'WORK_LIST',

  /**
   * Prehľad prác dodávateľa
   */
  WORK_LIST_SUPPLIER = 'WORK_LIST_SUPPLIER',

  /**
   * Detail práce
   */
  WORK_DETAIL = 'WORK_DETAIL',

  /**
   * Vytvorenie práce
   */
  WORK_CREATE = 'WORK_CREATE',

  /**
   * Editácia práce
   */
  WORK_UPDATE = 'WORK_UPDATE',

  /**
   * Zmazanie práce
   */
  WORK_DELETE = 'WORK_DELETE',

  /**
   * Zneplatnenie práce
   */
  WORK_INVALIDATE = 'WORK_INVALIDATE',

  /**
   * Obnovenie práce
   */
  WORK_REVALIDATE = 'WORK_REVALIDATE',

  /**
   * Prevzatie prác
   */
  WORK_TAKEOVER = 'WORK_TAKEOVER',

  /**
   * Import prác
   */
  WORK_IMPORT = 'WORK_IMPORT',
}
