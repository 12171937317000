import React from 'react';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { usePublicMechanismCategories } from '@modules/mechanism-category/mechanism-category-api';
import { useTenants } from '@modules/tenant/tenant-api';

export function Fields() {
  const mechanismCategories = usePublicMechanismCategories();
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné údaje"
          />
        }
      >
        <DictionaryEvidence.FieldOrder />
        <FormAutocomplete
          name="tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          disabled
        />
        <FormAutocomplete
          name="category"
          source={mechanismCategories}
          required={true}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__CATEGORY"
              defaultMessage="Kategória mechanizmu"
            />
          }
        />
        <FormTextField
          name="licencePlate"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__LICENCE_PLATE"
              defaultMessage="ŠPZ"
            />
          }
        />
        <DictionaryEvidence.FieldName />
        <DictionaryEvidence.FieldActive />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
