import { useContext, useRef } from 'react';
import {
  AbortableFetch,
  abortableFetch,
  DetailContext,
  ScheduleContext,
  SnackbarContext,
  useEventCallback,
} from '@eas/common-web';
import { Messages } from '@enums';

const activateJob = (jobUrl: string, id: string) => {
  return abortableFetch(`${jobUrl}/${id}/active`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

export function useActivateDialog() {
  const { source, refreshAll } = useContext(DetailContext);
  const { jobUrl } = useContext(ScheduleContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const callApi = useEventCallback(async () => {
    try {
      source.setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = activateJob(jobUrl, source.data.id);

      await fetch.current.none();
      refreshAll();
    } catch (err) {
      const message = Messages.Common.UNEXPECTED_ERROR;

      showSnackbar(...message);
      return undefined;
    } finally {
      source.setLoading(false);
    }
  });

  const showButton = source.data?.active === false;

  return { callApi, showButton };
}
