import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { AuthProvider as OauthProvider } from 'react-oauth2-code-pkce';
import { getVsdConfig, getZsdConfig } from './auth-config-provider-api';

const findRedirectUri = (redirectUris: string[], hostname: string) => {
  return redirectUris.find((uri) => uri.includes(hostname)) ?? '';
};

export interface OauthConfigs {
  zsd: OauthConfigDto | null;
  vsd: OauthConfigDto | null;
}

export interface OauthConfigDto {
  provider: { authorizationUri: string; tokenUri: string; logoutUri: string };
  registration: { clientId: string; redirectUris: string[]; scopes: string[] };
}

export enum AuthConfigEnum {
  VSD = 'VSD',
  ZSD = 'ZSD',
}

export interface AuthContextType {
  authConfig: OauthConfigs;
  selectedConfig: AuthConfigEnum | null;
  setSelectedConfig: any;
  /*   setAuthConfig: (config: OauthConfigs) => void;
  ssoLogin: (investor: 'ZSD' | 'VSD') => void; */
}

export const AuthConfigContext = createContext<AuthContextType>({
  authConfig: {} as OauthConfigs,
  selectedConfig: null,
  setSelectedConfig: () => {},
});

export function AuthConfigProvider({ children }: { children: ReactNode }) {
  const [authConfig, setAuthConfig] = useState<OauthConfigs>({
    zsd: null,
    vsd: null,
  });

  const [selectedConfig, setSelectedConfig] = useState<AuthConfigEnum | null>(
    localStorage.getItem('CONFIG') ?? null
  );

  useEffect(() => {
    const loadAuthConfig = async () => {
      try {
        const [vsdResponse, zsdResponse] = await Promise.all([
          getVsdConfig(),
          getZsdConfig(),
        ]);

        if (!vsdResponse.ok) {
          throw new Error(`VSD response error: ${vsdResponse.status}`);
        }
        /*     if (!zsdResponse.ok) {
          throw new Error(`ZSD response error: ${zsdResponse.status}`);
        } */

        const [vsdConfig, zsdConfig] = await Promise.all([
          vsdResponse.json(),
          zsdResponse.json(),
          /*    zsdResponse.json(), */
        ]);

        setAuthConfig({ vsd: vsdConfig, zsd: zsdConfig });
      } catch {
        console.log('error');
      }
    };

    loadAuthConfig();
  }, []);

  if (!authConfig.vsd || !authConfig.zsd) return <></>;

  const hostname = window.location.hostname;

  const VSDconfig = {
    authorizationEndpoint: authConfig.vsd.provider.authorizationUri,
    tokenEndpoint: authConfig.vsd.provider.tokenUri,
    clientId: authConfig.vsd.registration.clientId,
    scope: authConfig.vsd.registration.scopes.join(' '),
    redirectUri: findRedirectUri(
      authConfig.vsd.registration.redirectUris,
      hostname
    ),
    decodeToken: false,
    autoLogin: false,
    logoutEndpoint: authConfig.vsd.provider.logoutUri,
    clearUrl: false,
  };
  const ZSDConfig = {
    authorizationEndpoint: authConfig.zsd.provider.authorizationUri,
    tokenEndpoint: authConfig.zsd.provider.tokenUri,
    clientId: authConfig.zsd.registration.clientId,
    scope: authConfig.zsd.registration.scopes.join(' '),
    redirectUri: findRedirectUri(
      authConfig.zsd.registration.redirectUris,
      hostname
    ),
    decodeToken: false,
    autoLogin: false,
    logoutEndpoint: authConfig.zsd.provider.logoutUri,
    clearUrl: false,
  };

  return (
    <AuthConfigContext.Provider
      value={{ authConfig, selectedConfig, setSelectedConfig }}
    >
      <OauthProvider
        authConfig={selectedConfig === 'VSD' ? VSDconfig : ZSDConfig}
      >
        {children}
      </OauthProvider>
    </AuthConfigContext.Provider>
  );
}
