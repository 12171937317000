import { Permission } from '@enums';

export const PrimaryJournalPermissions = [
  Permission.User.USER_LIST,
  Permission.Role.ROLE_LIST,
];

/**
 * Primary Journal - List permissions allowing to see evidence in menu, only one is necessary
 */
export const PrimaryJournalListPermissions = [
  Permission.PrimaryJournal.PRIMARY_JOURNAL_LIST,
  Permission.PrimaryJournal.PRIMARY_JOURNAL_READ_ASSIGNED,
];

/**
 * Partial Journal - List permissions allowing to see evidence in menu, only one is necessary
 */
export const PartialJournalListPermissions = [
  Permission.PartialJournal.PARTIAL_JOURNAL_LIST,
  Permission.PartialJournal.PARTIAL_JOURNAL_READ_ASSIGNED,
];

/**
 * Partial Journal - Permissions necessary to allow creating partial journal, all permissions are necessary
 */
export const PartialJournalCreatePermissions = [
  Permission.PartialJournal.PARTIAL_JOURNAL_DETAIL,
  Permission.PartialJournal.PARTIAL_JOURNAL_CREATE,
];

/**
 * Journal Entry - List permissions allowing to see evidence in menu, only one is necessary
 */
export const JournalEntryListPermissions = [
  Permission.JournalEntry.JOURNAL_ENTRY_LIST,
];

/**
 * Dictionary - Absence - List permissions allowing to see evidence in menu, only one is necessary
 */
export const AbsenceListPermissions = [Permission.Absence.ABSENCE_LIST];

/**
 * Dictionary - MechanismCategory - List permissions allowing to see evidence in menu, only one is necessary
 */
export const MechanismCategoryListPermissions = [
  Permission.MechanismCategory.MECHANISM_CATEGORY_LIST,
];

/**
 * Dictionary - Mechanism - List permissions allowing to see evidence in menu, only one is necessary
 */
export const MechanismListPermissions = [Permission.Mechanism.MECHANISM_LIST];

/**
 * Dictionary - Profession - List permissions allowing to see evidence in menu, only one is necessary
 */
export const ProfessionListPermissions = [
  Permission.Profession.PROFESSION_LIST,
];

/**
 * Dictionary - TBPP - List permissions allowing to see evidence in menu, only one is necessary
 */
export const TBPPListPermissions = [Permission.TBPP.TBPP_LIST];

/**
 * Dictionary - WorkCategory - List permissions allowing to see evidence in menu, only one is necessary
 */
export const WorkCategoryListPermissions = [
  Permission.WorkCategory.WORK_CATEGORY_LIST,
];

/**
 * Dictionary - Work - List permissions allowing to see evidence in menu, only one is necessary
 */
export const WorkListPermissions = [Permission.Work.WORK_LIST];
