import { useRef } from 'react';
import {
  AbortableFetch,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl } from './../../../enums/evidence-url';

/**
 * Api call
 *
 * @param id
 */
function callApi(id: string) {
  return abortableFetch(
    `${EvidenceApiUrl.NOTIFICATION_RECEIVED}/${id}/unread`,
    {
      method: 'PUT',
    }
  );
}

export function useUnread({ refresh }: { refresh: () => void }) {
  /**
   * Fetch ref.
   */
  const fetch = useRef<AbortableFetch | null>(null);

  const handleUnread = useEventCallback(async (id: string) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = callApi(id);

      await fetch.current.raw();

      refresh();
    } catch (err) {
      if (err instanceof Error) {
        if (err.name !== 'AbortError') {
          throw err;
        }
      } else {
        console.log('Unexpected error', err);
      }
    }
  });

  return { handleUnread };
}
