import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  FilesContext,
  TableFieldCellProps,
  TableFieldToolbarButton,
} from '@eas/common-web';

export function FileTableActionCell(props: TableFieldCellProps<any>) {
  const { getFileUrl } = useContext(FilesContext);

  return (
    <TableFieldToolbarButton
      title={
        <FormattedMessage
          id="EAS_FILE_TABLE_BTN_DOWNLOAD"
          defaultMessage="Stiahnuť"
        />
      }
      IconComponent={GetAppIcon}
      disabled={false}
      show={true}
      onClick={
        ((e: React.MouseEvent<HTMLElement>) => e.stopPropagation()) as any
      }
      href={getFileUrl(props.rowValue.file.id)}
    />
  );
}
