import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { MechanismCategory } from '@models';
import { useTenants } from '@modules/tenant/tenant-api';
import { useIntl } from 'react-intl';

export function useColumns(): TableColumn<MechanismCategory>[] {
  const intl = useIntl();

  const { columnOrder, columnName, columnActive } =
    DictionaryEvidence.useDictionaryColumns<MechanismCategory>();

  return [
    columnOrder,
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISM_CATEGORY__TABLE__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    columnName,
    columnActive,
  ];
}
