import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  listItemsFactory,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';

import { useEvidencePermission } from '@utils/permission-hook';
import { useIntl } from 'react-intl';
import { useColumns } from './work-category-columns';
import { Fields } from './work-category-fields';
import { Me, WorkCategory } from '@models';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { publicDictionaryListItems } from '@utils/functions';
import { useValidationSchema } from './work-category-schema';
import { v4 as uuidv4 } from 'uuid';
import { TableToolbarImportButton } from '@components/table-toolbar-import-button/table-toolbar-import-button';
import { handleApiErrors } from '@utils/error-handler';
import { regionApiErrors } from 'src/enums/messages/api-errors';

export const WorkCategories = () => {
  const intl = useIntl();
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.WorkCategory.WORK_CATEGORY_CREATE],
      EDIT: [Permission.WorkCategory.WORK_CATEGORY_UPDATE],
      REMOVE: [Permission.WorkCategory.WORK_CATEGORY_DELETE],
      REFRESH: [Permission.WorkCategory.WORK_CATEGORY_DETAIL],
      SHARE: [Permission.WorkCategory.WORK_CATEGORY_DETAIL],
      CHECK: [
        Permission.WorkCategory.WORK_CATEGORY_CREATE,
        Permission.WorkCategory.WORK_CATEGORY_UPDATE,
      ],
      SAVE: [
        Permission.WorkCategory.WORK_CATEGORY_CREATE,
        Permission.WorkCategory.WORK_CATEGORY_UPDATE,
      ],
      CLOSE: [
        Permission.WorkCategory.WORK_CATEGORY_CREATE,
        Permission.WorkCategory.WORK_CATEGORY_UPDATE,
      ],
    },
  });

  const defaultWorkCategory: WorkCategory = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<WorkCategory>({
    version: 2,
    identifier: 'WORK_CATEGORY',
    apiProps: {
      url: EvidenceApiUrl.WORK_CATEGORY,
      listItems: listItemsFactory<WorkCategory>({
        listItemsMethod: (url, params) => {
          handleSwitchSupplier();
          return publicDictionaryListItems(url, params);
        },
      }),
      ...handleApiErrors(regionApiErrors.WORK_CATEGORY, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__WORK_CATEGORY__TABLE__TITLE',
        defaultMessage: 'Práca - Typ vedenia',
      }),
      showReportButton: false,
      toolbarProps: {
        after: hasPermission(Permission.WorkCategory.WORK_CATEGORY_IMPORT) && (
          <TableToolbarImportButton />
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__WORK_CATEGORY__DETAIL__TITLE',
          defaultMessage: 'Správa typu vedenia',
        }),
        disableBtn: (button) =>
          (['NEW', 'EDIT'].includes(button) && !!user?.supplier) ||
          (button === 'NEW' && !user?.tenant),
        showBtn: permissions,
      },
      initNewItem: () => defaultWorkCategory,
    },
  });

  return <Evidence {...evidence} />;
};
