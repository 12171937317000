import {
  abortableFetch,
  EvidenceContext,
  Params,
  TableActionButton,
  TableToolbarButton,
} from '@eas/common-web';
import React, { useCallback, useContext } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { FormattedMessage } from 'react-intl';
import { FormImportField } from '@components/form-import-field/form-import-field';
import * as Yup from 'yup';

function useValidationSchema() {
  return Yup.object<{ file: File }>().shape({
    file: Yup.mixed().nullable().required(),
  });
}

export const TableToolbarImportButton = () => {
  const { apiUrl, tableRef } = useContext(EvidenceContext);

  // const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = useValidationSchema();

  const uploadCsv = useCallback(
    (params: Params, selected: string[], formData: { file: File | null }) => {
      if (!formData.file) throw new Error('Nebol vložený CSV súbor');

      const dto = new FormData();
      dto.append('file', formData.file);

      return abortableFetch(apiUrl + '/import', {
        method: 'POST',
        body: dto,
      });
    },
    [apiUrl]
  );

  return (
    <TableActionButton
      ButtonComponent={TableToolbarButton}
      promptKey={tableRef.current?.tableId + '_IMPORT'}
      apiCall={uploadCsv}
      refreshAfterConfirm
      buttonLabel={<PublishIcon />}
      buttonTooltip="Importovať súbor CSV"
      dialogTitle="Import súboru CSV"
      dialogText="Pre import dát do číselníka, vložte súbor."
      formValidationSchema={validationSchema}
      FormFields={() => (
        <FormImportField
          name="file"
          label={
            <FormattedMessage
              id="ESD____TABLE_TOOLBAR_DIALOG__FIELD_LABEL__FILE"
              defaultMessage="Súbor CSV"
            />
          }
          acceptedFormats={['.csv']}
          required
        />
      )}
      /*   onError={async (err) => {
        if (err.code === 'OBJECT_NOT_IN_STATE_FOR_THIS_OPERATION') {
          showSnackbar(
            `Chyba volání akce: OBJECT_NOT_IN_STATE_FOR_THIS_OPERATION

Zrušit lze pouze rezervace ve stavu "Rozpracovaná" a "Nová plánovaná".`,
            SnackbarVariant.ERROR
          );
        }
      }} */
    />
  );
};
