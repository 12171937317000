import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { TBPP } from '@models';

export function useWorkTbpps(params?: Params) {
  return useAutocompleteSource<TBPP>({
    url: `${EvidenceApiUrl.TBPP}/autocomplete`,
    params,
  });
}

export function usePublicWorkTbpps(params?: Params) {
  return useAutocompleteSource<TBPP>({
    url: `${EvidenceApiUrl.TBPP}/autocomplete/public`,
    params,
  });
}

export function useSupplierWorkTbpps(params?: Params) {
  return useAutocompleteSource<TBPP>({
    url: `${EvidenceApiUrl.TBPP}/autocomplete/supplier`,
    params,
  });
}
