import React from 'react';
import {
  DictionaryEvidence,
  FormAutocomplete,
  FormTextField,
} from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { useSupplierMechanismCategories } from '@modules/mechanism-category/mechanism-category-api';

export function MechanismCreateDialog() {
  const mechanismCategories = useSupplierMechanismCategories();

  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_MECHANISMS__COLLECTION__FIELD_LABEL__NAME"
            defaultMessage="Názov"
          />
        }
        required
      />
      <FormAutocomplete
        name="category"
        source={mechanismCategories}
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_MECHANISMS__DETAIL__FIELD_LABEL__CATEGORY"
            defaultMessage="Kategória mechanizmu"
          />
        }
        required
      />
      <FormTextField
        name="licencePlate"
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_MECHANISMS__DETAIL__FIELD_LABEL__LICENCE_PLATE"
            defaultMessage="ŠPZ"
          />
        }
      />
    </>
  );
}
