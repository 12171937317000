import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { SupplierAutocomplete } from '@models';

export function useSuppliers(params?: Params) {
  return useAutocompleteSource<SupplierAutocomplete>({
    url: EvidenceApiUrl.SUPPLIER + '/autocomplete',
    params,
  });
}
