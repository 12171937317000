import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const LOGIN: MessageType<
  'NO_SUCH_USER' | 'WRONG_PASSWORD' | 'DUPLICATE_ACCOUNT' | 'ERROR'
> = {
  NO_SUCH_USER: [
    'Užívateľ s týmto prihlasovacím menom neexistuje.',
    SnackbarVariant.ERROR,
  ],
  WRONG_PASSWORD: ['Zadané heslo je nesprávné.', SnackbarVariant.ERROR],
  DUPLICATE_ACCOUNT: ['Účet bol zablokovaný.', SnackbarVariant.ERROR],
  ERROR: ['Prihlásenie zlyhalo.', SnackbarVariant.ERROR],
};

export default { LOGIN };
