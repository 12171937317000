export enum ExportTag {
  INTERNAL = 'INTERNAL',
  EXPORT_REQUESTS_DETAIL = 'EXPORT_REQUESTS_DETAIL',
  EXPORT_REQUESTS_GRID = 'EXPORT_REQUESTS_GRID',
  EXPORT_TEMPLATES_DETAIL = 'EXPORT_TEMPLATES_DETAIL',
  EXPORT_TEMPLATES_GRID = 'EXPORT_TEMPLATES_GRID',
  SCHEDULE_JOBS_DETAIL = 'SCHEDULE_JOBS_DETAIL',
  SCHEDULE_JOBS_GRID = 'SCHEDULE_JOBS_GRID',

  SCHEDULE_RUNS_DETAIL = 'SCHEDULE_RUNS_DETAIL',
  SCHEDULE_RUNS_GRID = 'SCHEDULE_RUNS_GRID',
  ALOG_GRID = 'ALOG_GRID',

  PRIMARY_JOURNAL_GRID = 'PRIMARY_JOURNAL_GRID',
  PARTIAL_JOURNAL_GRID = 'PARTIAL_JOURNAL_GRID',
  JOURNAL_ENTRY_GRID = 'JOURNAL_ENTRY_GRID',
}
