import React from 'react';
import { SnackbarVariant } from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { MessageType } from './message-type';

const SUPPLIER_SWITCH_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__SUCCESS"
      defaultMessage="Zastupovanie dodávateľa bolo úspešne aktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__ERROR"
      defaultMessage="Zastupovanie dodávateľa sa nepodarilo. Pre sprístupnenie správy dodávateľských číselníkov obnovte detail dodávateľa."
    />,
    SnackbarVariant.ERROR,
  ],
};

const PARTIAL_JOURNAL_SWITCH_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_SWITCH_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_SWITCH_SUPPLIER__SUCCESS"
      defaultMessage="Prepnutie dodávateľa prebehlo úspešne."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_SWITCH_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_SWITCH_SUPPLIER__ERROR"
      defaultMessage="Prepnutie dodávateľa sa nepodarilo. Pre sprístupnenie editácie detailu dielčieho denníka obnovte stránku."
    />,
    SnackbarVariant.ERROR,
  ],
};

const PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER__SUCCESS"
      defaultMessage="Prepnutie dodávateľa prebehlo úspešne."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER__ERROR"
      defaultMessage="Prepnutie dodávateľa sa nepodarilo. Pre sprístupnenie editácie detailu denného záznamu obnovte stránku."
    />,
    SnackbarVariant.ERROR,
  ],
};

const PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER__SUCCESS"
      defaultMessage="Odstránenie dodávateľa z kontextu prebehlo úspešne."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER__ERROR"
      defaultMessage="Odstránenie dodávateľa z kontextu sa nepodarilo. Pre sprístupnenie editácie detailu denného záznamu obnovte stránku."
    />,
    SnackbarVariant.ERROR,
  ],
};

const REMOVE_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_REMOVE_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_REMOVE_SUPPLIER__SUCCESS"
      defaultMessage="Správa zdieľaných čísleníkov povolená."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_REMOVE_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_REMOVE_SUPPLIER__ERROR"
      defaultMessage="Prístup k správe zdieľaných číselníkov sa nepodaril. Skúste prosím obnoviť prehľad."
    />,
    SnackbarVariant.ERROR,
  ],
};

const SUPPLIER_SWITCH_TENANT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_INVESTOR__SUCCESS"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_INVESTOR__SUCCESS"
      defaultMessage="Zastupovanie vlastníka bolo úspešne aktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_INVESTOR__ERROR"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_INVESTOR__ERROR"
      defaultMessage="Pri aktivovaní zástupu vlastníka nastala chyba. "
    />,
    SnackbarVariant.ERROR,
  ],
};

const SUPPLIER_REMOVE_TENANT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_INVESTOR__SUCCESS"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_INVESTOR__SUCCESS"
      defaultMessage="Zastupovanie vlastníka bolo úspešne deaktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_INVESTOR__ERROR"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_INVESTOR__ERROR"
      defaultMessage="Pri deaktivácii zástupu vlastníka nastala chyba."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default {
  SUPPLIER_SWITCH_SUPPLIER,
  PARTIAL_JOURNAL_SWITCH_SUPPLIER,
  PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER,
  PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER,
  REMOVE_SUPPLIER,
  SUPPLIER_SWITCH_TENANT,
  SUPPLIER_REMOVE_TENANT,
};
