import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    alignItems: 'center',
  },
  paper: {
    width: 400,
    height: 400,
    position: 'relative',
    color: 'white',
    borderRadius: '18.25px',

    '&:before': {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      width: 400,
      height: 200,
      content: "''",
      display: 'block',
      background: theme.palette.primary.main,
      borderRadius: '18.25px',
      clipPath: 'ellipse(85% 100% at 33% 0%)',
    },

    '& *': {
      zIndex: 100,
    },
  },
  content: {
    padding: '80px 40px',
    width: '100%',

    '& > :first-child': {
      marginBottom: 80,
    },

    '& > :last-child': {
      marginTop: 40,
    },
  },
  input: {
    backgroundColor: 'transparent',
  },
  actions: {
    marginTop: 40,
  },
  button: {
    textTransform: 'none',
    borderRadius: '18.25px',
    width: 200,
  },
}));
