import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { Me, PartialJournal } from '@models';
import { useContext } from 'react';
import { PartialJournalState } from 'src/enums/partial-journal-state';

export function useSignPartialJournalByTimestamp() {
  const {
    source: { data },
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);

  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (journalId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.PARTIAL_JOURNAL}/${journalId}/sign`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          type: 'TIMESTAMP',
          signedBy: { id: user?.person?.id },
          timestamp: new Date(),
        }),
      }
    );
  };

  const showButton =
    data?.parent?.state !== PrimaryJournalState.CANCELLED &&
    data?.state === PartialJournalState.CLOSED &&
    hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_SIGN);

  return { callApi, showButton };
}
