import { ContentUnavailablePanel } from '@components/content-unavailable-panel/content-unavailable-panel';
import SignaturesTableField from '@composite/signatures-field/signatures-table-field';
import { DetailContext, DetailHandle } from '@eas/common-web';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

export function PrimaryJournalSignaturesFields() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  return isPrimaryJournalAssigned(data?.id) ? (
    <SignaturesTableField />
  ) : (
    <ContentUnavailablePanel
      title={
        <FormattedMessage
          id="ESD__PRIMARY_JOURAL__DETAIL__SIGNATURES_UNAVAILABLE__TITLE"
          defaultMessage="Chýbajúce oprávnenia"
        />
      }
      reason={
        <FormattedMessage
          id="ESD__PRIMARY_JOURAL__DETAIL__SIGNATURES_UNAVAILABLE__REASON"
          defaultMessage="Nie ste priamym členom aktuálne otvoreného hlavného denníka preto nemáte oprávnenie k zobrazovaniu jeho členov"
        />
      }
    ></ContentUnavailablePanel>
  );
}
