export enum ContextSwitchSupplierType {
  /**
   * Supplier detail
   */
  SUPPLIER = 'SUPPLIER',

  /**
   * Partial journal detail
   */
  PARTIAL_JOURNAL = 'PARTIAL_JOURNAL',

  /**
   * Journal entry of partial journal detail
   */
  PARTIAL_JOURNAL_ENTRY = 'PARTIAL_JOURNAL_ENTRY',

  /**
   * Journal entry of primary journal detail
   */
  PRIMARY_JOURNAL_ENTRY = 'PRIMARY_JOURNAL_ENTRY',
}
