/**
 * Typ notifikace
 */
export enum NotificationType {
  /**
   * Emailová notifikace
   */
  EMAIL = 'EMAIL',

  /**
   * Aplikační notifikace
   */
  APPLICATION = 'APPLICATION',
}

/**
 *  Událost
 */
export enum NotificationEventType {
  /**
   * Zrušení hlavního deníku
   */
  CANCEL_PRIMARY_JOURNAL = 'CANCEL_PRIMARY_JOURNAL',
  /**
   * Zrušení dílčího deníku
   */
  CANCEL_PARTIAL_JOURNAL = 'CANCEL_PARTIAL_JOURNAL',
}
