import SignaturesTableField from '@composite/signatures-field/signatures-table-field';
import React from 'react';

export function JournalEntrySignaturesFields() {
  return (
    <>
      <SignaturesTableField />
    </>
  );
}
