import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff4e5',
    padding: '10px 20px 10px 10px',
    color: '#663c00',
  },
  icon: {
    color: '#ff9800',
    marginTop: 5,
    marginRight: 10,
  },
  title: {
    marginBottom: 5,
  },
  reason: {},
});
