import {
  abortableFetch,
  DetailContext,
  DetailHandle,
  UserContext,
} from '@eas/common-web';
import { EvidenceApiUrl, PrimaryJournalState } from '@enums';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useContext } from 'react';

export function useSignPrimaryJournalByTimestamp() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const { user } = useContext(UserContext);

  const callApi = () => {
    return abortableFetch(
      `${EvidenceApiUrl.PRIMARY_JOURNAL}/${data?.id}/sign`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          type: 'TIMESTAMP',
          signedBy: { id: user?.person?.id },
          timestamp: new Date(),
        }),
      }
    );
  };

  const showButton =
    data?.state === PrimaryJournalState.CLOSED &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton };
}
