import {
  SnackbarContext,
  useEventCallback,
  UserContext,
} from '@eas/common-web';
import { ContextSwitchSupplierType, Messages } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';
import { switchTenant, switchSupplier } from '../modules/users/user-api';

export const useUserContextSwitch = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const { user, reload } = useContext<UserContext<Me>>(UserContext);

  const getSwitchSupplierMessage = (switchType?: ContextSwitchSupplierType) => {
    switch (switchType) {
      case ContextSwitchSupplierType.SUPPLIER:
        return Messages.User.SUPPLIER_SWITCH_SUPPLIER;
      case ContextSwitchSupplierType.PARTIAL_JOURNAL:
        return Messages.User.PARTIAL_JOURNAL_SWITCH_SUPPLIER;
      case ContextSwitchSupplierType.PARTIAL_JOURNAL_ENTRY:
        return Messages.User.PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER;
      case ContextSwitchSupplierType.PRIMARY_JOURNAL_ENTRY:
        return Messages.User.PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER;
      default:
        return Messages.User.REMOVE_SUPPLIER;
    }
  };

  const handleSwitchSupplier = useEventCallback(
    async (supplierId?: string, switchType?: ContextSwitchSupplierType) => {
      if (supplierId === user?.supplier?.id) {
        return;
      }

      const message = getSwitchSupplierMessage(switchType);

      try {
        await switchSupplier(supplierId).none();
        reload();
      } catch (e) {
        showSnackbar(...message.ERROR, false);
      }
    }
  );

  const handleSwitchTenant = useEventCallback(async (tenantId?: string) => {
    if (tenantId === user?.tenant?.id) {
      return;
    }

    const message = tenantId
      ? Messages.User.SUPPLIER_SWITCH_TENANT
      : Messages.User.SUPPLIER_REMOVE_TENANT;

    try {
      await switchTenant(tenantId).none();

      showSnackbar(...message.SUCCESS);
    } catch (e) {
      showSnackbar(...message.ERROR, false);
    }
  });

  return { handleSwitchSupplier, handleSwitchTenant };
};
