import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import {
  AssignmentType,
  EvidenceApiUrl,
  PartialJournalListPermissions,
  Permission,
} from '@enums';
import { Me, PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useContext } from 'react';

export const PARTIAL_SOURCE = 'PARTIAL_SOURCE';
export const JOURNAL_ENTRY = 'JOURNAL_ENTRY';
export const PERSON_ASSSIGNMENT = 'PERSON_ASSSIGNMENT';

function usePartialJournalSource(): HookedSource<PrimaryJournal> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [PARTIAL_SOURCE]: {
      source,
      shouldLoad: () =>
        !!PartialJournalListPermissions.find((permission) =>
          hasPermission(permission)
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.PARTIAL_JOURNAL}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'parent.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useJournalEntryHookedSource(): HookedSource<PrimaryJournal> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  return {
    [JOURNAL_ENTRY]: {
      source,
      shouldLoad: ({ id }) =>
        hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_LIST) &&
        isPrimaryJournalAssigned(id),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journal.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function usePersonAssignmentHookedSource(): HookedSource<PrimaryJournal> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  return {
    [PERSON_ASSSIGNMENT]: {
      source,
      shouldLoad: ({ id }) =>
        hasPermission(
          Permission.PersonAssignmentPrimaryJournal
            .PERSON_ASSIGNMENT_PRIMARY_JOURNAL_LIST
        ) && isPrimaryJournalAssigned(id),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.PERSON}/assignment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'primaryJournal.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: AssignmentType.PRIMARY_JOURNAL,
            },
          ],
        });
      },
    },
  };
}

export function usePrimaryJournalHookedSources(): HookedSource<PrimaryJournal> {
  const partialJournals = usePartialJournalSource();
  const journalEntries = useJournalEntryHookedSource();
  const personAssignments = usePersonAssignmentHookedSource();

  return { ...partialJournals, ...journalEntries, ...personAssignments };
}
