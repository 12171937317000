import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import {
  formFieldFactory,
  SnackbarContext,
  SnackbarVariant,
  TextField,
  useEventCallback,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  uploadButton: {
    boxShadow: '0 0 0',
    padding: '2pt',
    height: 23,
    '& p': {
      // contradict the default 'none'
      display: 'block',
    },
  },
  downloadButton: {
    minWidth: '0',
    marginLeft: '2pt',
    boxShadow: '0 0 0',
    padding: '2pt',
    height: 23,
    '& p': {
      // contradict the default 'none'
      display: 'block',
    },
  },
}));

function CustomImportField({
  form,
  disabled = false,
  value,
  onChange,
  acceptedFormats,
}: {
  form?: string;
  disabled?: boolean;
  value: File;
  onChange: (value: File | null) => void;
  acceptedFormats: string[];
}) {
  const { uploadButton, downloadButton } = useStyles();
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();

  const handleUpload = useEventCallback(
    async (event: React.ChangeEvent<any>) => {
      const input = event.currentTarget;
      const files: File[] = event.currentTarget.files;

      if (files.length === 0) {
        onChange(null);
        return;
      }

      const file = files[0];

      /**
       * Check the extension of a file and show error message
       * if not included in the ACCEPTED_FORMATS array
       */

      const extensionIsValid = Boolean(
        acceptedFormats.find((ext) => file.name.endsWith(ext))
      );
      if (acceptedFormats.length && !extensionIsValid) {
        onChange(null);
        const message = intl.formatMessage(
          {
            id: 'EAS_FILES_MSG_ERROR_FILE_TYPE',
            defaultMessage:
              'Nepovolený typ súboru. Povolené hodnoty sú: {fileTypes}',
          },
          {
            fileTypes: acceptedFormats.join(','),
          }
        );
        showSnackbar(message, SnackbarVariant.ERROR);
        return;
      }

      /*
        Reset file input, so one can load the same file again with trigering the onChange event.
        The event will not be triggered otherwise for the same file.
      */
      input.value = null;

      if (file !== undefined) {
        onChange(file);
      } else {
        onChange(null);
      }
    }
  );

  const handleRemove = useEventCallback(() => {
    onChange(null);
  });

  return (
    <TextField
      form={form}
      autoFocus={true}
      disabled={true}
      value={value?.name ?? ''}
      startAdornment={
        <>
          {!value && !disabled && (
            <MuiButton
              size="small"
              classes={{ root: uploadButton }}
              disabled={disabled}
              variant="contained"
              component="label"
            >
              <Typography>
                <FormattedMessage
                  id="EAS_FILE_BTN_SELECT"
                  defaultMessage="Vložit soubor"
                />
              </Typography>
              <input
                type="file"
                accept={acceptedFormats.join(',')}
                style={{ display: 'none' }}
                onChange={handleUpload}
              />
            </MuiButton>
          )}

          {value && !disabled && (
            <MuiButton
              size="small"
              classes={{ root: downloadButton }}
              variant="contained"
              onClick={handleRemove}
              disabled={disabled}
            >
              <ClearIcon />
            </MuiButton>
          )}
        </>
      }
    />
  );
}

export const FormImportField = formFieldFactory(CustomImportField);
