/**
 * Dictionary - TBPP permissions
 */
export enum PermissionTBPP {
  /**
   * Prehľad TBPP
   */
  TBPP_LIST = 'TBPP_LIST',

  /**
   * Prehľad TBPP dodávateľa
   */
  TBPP_LIST_SUPPLIER = 'TBPP_LIST_SUPPLIER',

  /**
   * Detail TBPP
   */
  TBPP_DETAIL = 'TBPP_DETAIL',

  /**
   * Vytvorenie TBPP
   */
  TBPP_CREATE = 'TBPP_CREATE',

  /**
   * Editácia TBPP
   */
  TBPP_UPDATE = 'TBPP_UPDATE',

  /**
   * Zmazanie TBPP
   */
  TBPP_DELETE = 'TBPP_DELETE',

  /**
   * Zneplatnenie TBPP
   */
  TBPP_INVALIDATE = 'TBPP_INVALIDATE',

  /**
   * Obnovenie TBPP
   */
  TBPP_REVALIDATE = 'TBPP_REVALIDATE',

  /**
   * Prevzatie TBPP
   */
  TBPP_TAKEOVER = 'TBPP_TAKEOVER',

  /**
   * Import TBPUU
   */
  TBPP_IMPORT = 'TBPP_IMPORT',
}
