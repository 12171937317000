/**
 * Region timesheet permissions
 */
export enum PermissionJournalEntryTimesheet {
  /**
   * Prehľad odpracovaných dôb
   */
  JOURNAL_ENTRY_TIMESHEET_LIST = 'JOURNAL_ENTRY_TIMESHEET_LIST',

  /**
   * Detail odpracovanej doby
   */
  JOURNAL_ENTRY_TIMESHEET_DETAIL = 'JOURNAL_ENTRY_TIMESHEET_DETAIL',

  /**
   * Vytvorenie odpracovanej doby
   */
  JOURNAL_ENTRY_TIMESHEET_CREATE = 'JOURNAL_ENTRY_TIMESHEET_CREATE',

  /**
   * Editácia odpracovanej doby
   */
  JOURNAL_ENTRY_TIMESHEET_UPDATE = 'JOURNAL_ENTRY_TIMESHEET_UPDATE',

  /**
   * Zmazanie odpracovanej doby
   */
  JOURNAL_ENTRY_TIMESHEET_DELETE = 'JOURNAL_ENTRY_TIMESHEET_DELETE',
}
