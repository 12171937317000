import { ApiFilterOperation, FieldFilter } from '@eas/common-web';
import { useIntl } from 'react-intl';

export const useFilterFields = () => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__SUPPLIER_DICTIONARY__TABLE__COLUMN_FILTER__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      filterkey: 'name',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
  ];
};
