export enum AssemblyType {
  /**
   * Montáž
   */
  ASSEMBLY = 'ASSEMBLY',

  /**
   * Znovumontáž
   */
  REASSEMBLY = 'REASSEMBLY',

  /**
   * Demontáž
   */
  DISASSEMBLY = 'DISASSEMBLY',
}
