import {
  AbortableFetch,
  abortableFetch,
  DetailContext,
  DetailHandle,
} from '@eas/common-web';
import { EvidenceApiUrl, PrimaryJournalState } from '@enums';
import { PrimaryJournal } from '@models';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useContext, useRef } from 'react';

export const KEP_PRIMARY_JOURNAL_ID = 'KEP_PRIMARY_JOURNAL_ID';

export function useSignPrimaryJournalByKepDialog() {
  const {
    source: { data, setLoading },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const fetch = useRef<AbortableFetch | null>(null);

  const callApi = async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }
      setLoading(true);

      fetch.current = abortableFetch(
        `${EvidenceApiUrl.PRIMARY_JOURNAL}/${data?.id}/kep/sign`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
          body: JSON.stringify({
            returnUrlTemplate: `${window.location.origin}/disigcallback.aspx?status=@(status)&sessionId=@(sessionId)`,
          }),
        }
      );

      const response = await fetch.current.text();

      setLoading(false);

      if (response && data?.id) {
        localStorage.setItem(KEP_PRIMARY_JOURNAL_ID, data?.id);

        window.location.href = response;
      }
    } catch {
      setLoading(false);
    }
  };

  const showButton =
    data?.state === PrimaryJournalState.CLOSED &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton };
}
