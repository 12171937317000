import React, { useContext } from 'react';
import {
  FormContext,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  TableFieldActionButton,
  TableFieldContext,
} from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Signature } from '@models';
import { FormattedMessage } from 'react-intl';
import { useSignAttachmentByTimestamp } from '@modules/journal-entry/dialog-actions/sign-attachment-timestamp-hook';
import { useDeleteSignatureAttachment } from '@modules/journal-entry/dialog-actions/delete-attachment-signature-hook';

const useStyles = makeStyles(() => ({
  tableActions: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    height: 30,
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 12px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  buttonGroup: {
    display: 'flex',
    paddingBottom: '1px',
    // marginLeft: -5, // offset first icon left padding
  },
}));

export function SignaturesTableFieldToolbar(
  attachmentId: string,
  selectedIndex: number | undefined,
  setSelectedIndex: any,
  readApiCall: any
) {
  const { value } = useContext<TableFieldContext<Signature>>(TableFieldContext);
  const { setFieldValue } = useContext(FormContext);

  const classes = useStyles();

  const { showButton: showTimestampButton, callApi: timestampSignApiCall } =
    useSignAttachmentByTimestamp();

  const { showButton: showDeleteButton, callApi: deleteSignatureApiCall } =
    useDeleteSignatureAttachment();

  return (
    <>
      {(showDeleteButton || showTimestampButton) && (
        <div className={classes.tableActions}>
          <div className={classes.buttonGroup}>
            {showTimestampButton && (
              <TableFieldActionButton
                dialogTitle={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR__TIMESTAMP_SIGN__DIALOG_TITLE"
                    defaultMessage="Podpísanie časovou pečiatkou"
                  />
                }
                dialogText={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR__TIMESTAMP_SIGN__DIALOG_TEXT"
                    defaultMessage="Naozaj chcte podpísať prílohu?"
                  />
                }
                buttonLabel={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR__TIMESTAMP_SIGN__BUTTON_LABEL"
                    defaultMessage="Podpísanie časovou pečiatkou"
                  />
                }
                promptKey="signAttachmentByTimestamp"
                successMessage="Úspešně podpísané"
                apiCall={() => timestampSignApiCall(attachmentId)}
                ButtonComponent={PrimaryDetailActionbarButton}
                buttonDisabled={selectedIndex !== undefined}
                onSuccess={async () => {
                  const response = readApiCall(attachmentId);
                  const json = await response.json();

                  if (json) {
                    setFieldValue('signatures', json.signatures);
                  }
                }}
              />
            )}
            {showDeleteButton && (
              <TableFieldActionButton
                promptKey="deleteSignatureAttachment"
                dialogTitle={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR__DELETE__DIALOG_TITLE"
                    defaultMessage="Smazanie podpisu"
                  />
                }
                dialogText={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR__DELETE__DIALOG_TEXT"
                    defaultMessage="Naozaj chcte smazať podpis?"
                  />
                }
                buttonLabel={
                  <FormattedMessage
                    id="ESD_ATTACHMENT_SIGNATURE__TOOLBAR_DELETE__BUTTON_TOOLTIP"
                    defaultMessage="Smazanie podpisu"
                  />
                }
                successMessage="Podpis bol úspešne zmazaný"
                ButtonComponent={SecondaryDetailActionbarButton}
                apiCall={() =>
                  deleteSignatureApiCall(
                    attachmentId,
                    value?.[selectedIndex as number]?.id
                  )
                }
                buttonDisabled={selectedIndex === undefined}
                onSuccess={async () => {
                  const response = readApiCall(attachmentId);
                  const json = await response.json();

                  if (json) {
                    setFieldValue('signatures', json.signatures);
                    await setSelectedIndex(undefined);
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
