import { Work } from '@models';
import * as Yup from 'yup';

export function useValidationSchema() {
  return Yup.object<Work>().shape({
    category: Yup.mixed<Work['category']>().required(),
    name: Yup.string().nullable().required(),
    units: Yup.string().nullable().required(),
  });
}
