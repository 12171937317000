import { SnackbarVariant } from '@eas/common-web';

const getMessagePrefix = (type?: 'CREATE' | 'GET' | 'UPDATE' | 'DELETE') => {
  if (!type) {
    return '';
  }

  switch (type) {
    case 'CREATE':
      return 'Chyba vytvorenia dát: ';
    case 'GET':
      return 'Chyba načítania dát: ';
    case 'UPDATE':
      return 'Chyba úpravy údajov: ';
    case 'DELETE':
      return 'Chyba mazania dát: ';
    default:
      return '';
  }
};

export const handleApiError = (
  error: { code?: string; message?: string },
  messagesObject: { [key: string]: string },
  showSnackbar: (message: string, variant: SnackbarVariant) => void,
  type?: 'CREATE' | 'GET' | 'UPDATE' | 'DELETE'
) => {
  const messagePrefix = getMessagePrefix(type);
  let message;

  if (error?.code && error.code in messagesObject) {
    message = messagesObject[error.code as keyof typeof messagesObject];
  } else if (error?.message && error.message in messagesObject) {
    message = messagesObject[error.message as keyof typeof messagesObject];
  } else {
    message = error?.code || error?.message;
  }

  if (showSnackbar)
    showSnackbar(messagePrefix + message, SnackbarVariant.ERROR);

  throw error;
};

export const handleApiErrors = (
  errorMessages: { [key: string]: string },
  showSnackbar: (message: string, variant: SnackbarVariant) => void
) => ({
  handleDeleteError: (err: Error) =>
    handleApiError(err, errorMessages, showSnackbar, 'DELETE'),
  handleCreateError: (err: Error) =>
    handleApiError(err, errorMessages, showSnackbar, 'CREATE'),
  handleUpdateError: (err: Error) =>
    handleApiError(err, errorMessages, showSnackbar, 'UPDATE'),
  handleGetError: (err: Error) =>
    handleApiError(err, errorMessages, showSnackbar, 'GET'),
});
