import React, { useContext } from 'react';
import { AuthoredEvidence, Evidence, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me, WorkGroup } from '@models';
import { useEvidencePermission } from '@utils/permission-hook';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { useColumns } from './work-group-columns';
import { Fields } from './work-group-fields';
import { useValidationSchema } from './work-group-schema';

export function WorkGroups() {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const defaultWorkGroup: WorkGroup = {
    id: uuidv4(),
    name: '',
    members: [],
    tenant: user?.tenant,
  };

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.WorkGroup.WORKGROUP_CREATE],
      EDIT: [Permission.WorkGroup.WORKGROUP_UPDATE],
      REMOVE: [Permission.WorkGroup.WORKGROUP_DELETE],
      REFRESH: [Permission.WorkGroup.WORKGROUP_DETAIL],
      SHARE: [Permission.WorkGroup.WORKGROUP_DETAIL],
      CHECK: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
      SAVE: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
      CLOSE: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<WorkGroup>({
    version: 3,
    identifier: 'WORK_GROUP',
    apiProps: {
      url: EvidenceApiUrl.WORK_GROUP,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__WORK_GROUP__TABLE__TITLE',
        defaultMessage: 'Pracovné skupiny',
      }),
      showReportButton: false,
    },
    detailProps: {
      initNewItem: () => defaultWorkGroup,
      FieldsComponent: Fields,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__WORK_GROUP__DETAIL__TITLE',
          defaultMessage: 'Správa pracovnej skupiny',
        }),
        disableBtn: () => !user?.tenant,
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
}
