/**
 * Workgroup permissions
 */
export enum PermissionWorkGroup {
  /**
   * Prehľad pracovnej skupiny
   */
  WORKGROUP_LIST = 'WORKGROUP_LIST',

  /**
   * Detail pracovnej skupiny
   */
  WORKGROUP_DETAIL = 'WORKGROUP_DETAIL',

  /**
   * Vytvorenie pracovnej skupiny
   */
  WORKGROUP_CREATE = 'WORKGROUP_CREATE',

  /**
   * Editácia pracovnej skupiny
   */
  WORKGROUP_UPDATE = 'WORKGROUP_UPDATE',

  /**
   * Zmazanie pracovnej skupiny
   */
  WORKGROUP_DELETE = 'WORKGROUP_DELETE',

  /**
   * Zneplatnenie pracovnej skupiny
   */
  WORKGROUP_INVALIDATE = 'WORKGROUP_INVALIDATE',

  /**
   * Obnovenie pracovnej skupiny
   */
  WORKGROUP_REVALIDATE = 'WORKGROUP_REVALIDATE',
}
