import {
  ApiFilterOperation,
  HookedSource,
  useScrollableSource,
} from '@eas/common-web';
import { AssignmentType, EvidenceApiUrl } from '@enums';
import { PersonType } from '@models';
import { stubTrue } from 'lodash';

export const PERSON_SUPPLIER_ASSIGNMENT = 'PERSON_SUPPLIER_ASSIGNMENT';

function usePersonSupplierAssignmentHookedSource(): HookedSource<PersonType> {
  const source = useScrollableSource();

  return {
    [PERSON_SUPPLIER_ASSIGNMENT]: {
      source,
      shouldLoad: stubTrue,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.PERSON}/assignment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'person.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
            {
              field: 'type.id',
              operation: ApiFilterOperation.EQ,
              value: AssignmentType.SUPPLIER,
            },
          ],
        });
      },
    },
  };
}

export const usePersonHookedSources = () => {
  const personSupplierAssignments = usePersonSupplierAssignmentHookedSource();

  return {
    ...personSupplierAssignments,
  };
};
