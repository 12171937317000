import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CLOSE: MessageType<string> = {
  SUCCESS: ['Denník bol úspešně zatvorený', SnackbarVariant.SUCCESS],
  OPEN_JOURNAL_ENTRY_EXIST: [
    'Niektorý z denných záznamov je v stave "otvorený" ',
    SnackbarVariant.ERROR,
  ],
};

export default { CLOSE };
