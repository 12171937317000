import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { Mechanism } from '@models';
import { useTenants } from '@modules/tenant/tenant-api';
import { usePublicMechanismCategories } from '@modules/mechanism-category/mechanism-category-api';
import { useIntl } from 'react-intl';

export function useColumns(): TableColumn<Mechanism>[] {
  const intl = useIntl();

  const { columnName, columnActive, columnOrder } =
    DictionaryEvidence.useDictionaryColumns<Mechanism>();

  return [
    {
      datakey: 'tenant',
      sortkey: 'tenant.name',
      filterkey: 'tenant.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISM__TABLE__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTenants),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'category',
      sortkey: 'category.name',
      filterkey: 'category.id',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Kategória mechanizmu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        usePublicMechanismCategories
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'licencePlate',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS__TABLE__COLUMN__LICENCE_PLATE',
        defaultMessage: 'ŠPZ',
      }),
      width: 80,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    columnName,
    columnActive,
    columnOrder,
  ];
}
