import { abortableFetch, DetailContext, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';

export function useDuplicateJournalEntry() {
  const { source } = useContext(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const journalId = source.data?.id;

  const callApi = (entryId: string, formValues: { validityDate: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY}/${journalId}/duplicate`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(formValues),
      }
    );
  };

  const showButton = hasPermission(
    Permission.JournalEntry.JOURNAL_ENTRY_DUPLICATE
  );

  return { callApi, showButton };
}
