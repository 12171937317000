/**
 * User permissions
 */
export enum PermissionUser {
  /**
   * Prehľad užívateľov
   */
  USER_LIST = 'USER_LIST',

  /**
   * Detail užívateľa
   */
  USER_DETAIL = 'USER_DETAIL',

  /**
   * Editácia užívateľa
   */
  USER_UPDATE = 'USER_UPDATE',
}
