import { useEvidencePermission } from '@utils/permission-hook';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  createItemFactory,
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  getItemFactory,
  UserContext,
} from '@eas/common-web';
import { useColumns } from './supplier-columns';
import { Fields } from './supplier-fields';
import { ContextSwitchSupplierType, EvidenceApiUrl, Permission } from '@enums';
import { Me, Supplier } from '@models';
import { useValidationSchema } from './supplier-schema';
import { useSupplierDictionaryHookedSources } from './supplier-hooked-sources';
import { useUserContextSwitch } from '@utils/user-context-switch';

export function Suppliers() {
  const intl = useIntl();

  const { user } = useContext<UserContext<Me>>(UserContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const defaultSupplier: Supplier = {
    id: uuidv4(),
    tenant: user?.tenant,
    name: '',
  };

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Supplier.SUPPLIER_CREATE],
      EDIT: [Permission.Supplier.SUPPLIER_UPDATE],
      REMOVE: [Permission.Supplier.SUPPLIER_DELETE],
      REFRESH: [Permission.Supplier.SUPPLIER_DETAIL],
      SHARE: [Permission.Supplier.SUPPLIER_DETAIL],
      CHECK: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
      SAVE: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
      CLOSE: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
    },
  });

  const evidence = DictionaryEvidence.useDictionaryEvidence<Supplier>({
    version: 4,
    identifier: 'SUPPLIER',
    apiProps: {
      url: EvidenceApiUrl.SUPPLIER,
      hookedSources: useSupplierDictionaryHookedSources,
      getItem: getItemFactory<Supplier>({
        postProcess: async (supplier) => {
          await handleSwitchSupplier(
            supplier.id,
            ContextSwitchSupplierType.SUPPLIER
          );
          return supplier;
        },
      }),
      createItem: createItemFactory<Supplier>({
        postProcess: async (supplier) => {
          await handleSwitchSupplier(
            supplier.id,
            ContextSwitchSupplierType.SUPPLIER
          );
          return supplier;
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__SUPPLIER__TABLE__TITLE',
        defaultMessage: 'Dodávatelia',
      }),
      showReportButton: false,
    },
    detailProps: {
      initNewItem: () => defaultSupplier,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__SUPPLIER__DETAIL__TITLE',
          defaultMessage: 'Dodávatelia',
        }),
        showBtn: permissions,
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) && !user?.tenant,
      },
    },
  });

  return <Evidence {...evidence} />;
}
