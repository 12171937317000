import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { useIntl } from 'react-intl';
import { AuthoredEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { useColumns } from './person-columns';
import { Fields } from './person-fields';
import { EvidenceApiUrl, Permission } from '@enums';
import { PersonType } from '@models';
import { useValidationSchema } from './person-schema';
import { usePersonHookedSources } from './person-hooked-sources';

export function Person() {
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Person.PERSON_CREATE],
      EDIT: [Permission.Person.PERSON_UPDATE],
      REMOVE: [Permission.Person.PERSON_DELETE],
      REFRESH: [Permission.Person.PERSON_DETAIL],
      SHARE: [Permission.Person.PERSON_DETAIL],
      CHECK: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      SAVE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      CLOSE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<PersonType>({
    version: 5,
    identifier: 'PERSON',
    apiProps: {
      url: EvidenceApiUrl.PERSON,
      hookedSources: usePersonHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PERSON__TABLE__TITLE',
        defaultMessage: 'Osoby',
      }),
      showReportButton: false,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PERSON__DETAIL__TITLE',
          defaultMessage: 'Osoby',
        }),
        showBtn: permissions,
        // ActionBar: DataSourceActionbar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
