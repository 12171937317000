import { abortableFetch, UserContext } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';

export function useSignAttachmentByTimestamp() {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (attachmentId: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/${attachmentId}/sign`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          type: 'TIMESTAMP',
          signedBy: { id: user?.person?.id },
          timestamp: new Date(),
        }),
      }
    );
  };

  const showButton = hasPermission(
    Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_SIGN
  );

  return { callApi, showButton };
}
