import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const CLOSE: MessageType<string> = {
  SUCCESS: ['Hlavný denník bol úspešne zatvorený', SnackbarVariant.SUCCESS],
  OPEN_PARTIAL_JOURNAL_EXIST: [
    'Minimálne jeden z dielčích denníkov je v stave "Otvorený" alebo "Vytvorený"',
    SnackbarVariant.ERROR,
  ],
  OPEN_JOURNAL_ENTRY_EXIST: [
    'Minimálne jeden z denných záznamov je v stave "Otvorený" ',
    SnackbarVariant.ERROR,
  ],
};

const REOPEN: MessageType<string> = {
  SUCCESS: ['Hlavný denník bol úspešne znovuotvorený', SnackbarVariant.SUCCESS],
  CANNOT_CHANGE_STATE: [
    'Hlavný denník nie je v stave "Uzavretý"',
    SnackbarVariant.ERROR,
  ],
};

export default { CLOSE, REOPEN };
