import { DomainObject, TableFieldColumn } from '@eas/common-web';
import { useIntl } from 'react-intl';

export function useDefaultColumns<
  T extends DomainObject
>(): TableFieldColumn<T>[] {
  const intl = useIntl();
  return [
    {
      name: intl.formatMessage({
        id: 'ESD__DICTIONARY_SUPPLIER__TABLE__COLUMN__ORDER',
        defaultMessage: 'Poradie',
      }),
      datakey: 'order',
      sortkey: 'order',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__DICTIONARY_SUPPLIER__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 200,
    },
  ];
}
