import { useIntl } from 'react-intl';
import React from 'react';
import {
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  TableFilterCells,
} from '@eas/common-web';
import { JournalEntry, WeatherReport } from '@models';
import { useJournalEntryStates, useJournalTypes } from './journal-entry-api';
import { weatherCodes } from './fields/overview-fields';

export function useColumns(): TableColumn<JournalEntry>[] {
  const intl = useIntl();
  const { columnCreated, columnCreatedBy, columnUpdated, columnUpdatedBy } =
    AuthoredEvidence.useAuthoredColumns<JournalEntry>();

  return [
    {
      datakey: 'validityDate',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Platnosť',
      }),
      width: 120,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    /*     {
      datakey: 'comment',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Komentár',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    }, */
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Stav',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useJournalEntryStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useJournalEntryStates
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'journal.name',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Názov stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'journal.journalType',
      sortkey: 'journal.journalType.name',
      filterkey: 'journal.journalType.id',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Typ denníka',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useJournalTypes),
      valueMapper: TableCells.useSelectCellFactory(useJournalTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'sequenceNumber',
      name: intl.formatMessage({
        id: 'ESD_JOURNAL_ENTRY__COLUMN__NAME',
        defaultMessage: 'Číslo záznamu',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: columnCreated.datakey,
      name: columnCreated.name,
      width: 120,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  ];
}

export function useWeatherColumns(): TableFieldColumn<WeatherReport>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'cloudiness',
      name: intl.formatMessage({
        id: 'ESD__WEATHER__COLUMN__CLOUDINESS',
        defaultMessage: 'Oblačnosť',
      }),
      width: 100,
      CellComponent: (props: TableFieldCellProps<WeatherReport>) => {
        const value =
          weatherCodes[props.rowValue?.cloudiness] ??
          intl.formatMessage({
            id: 'ESD__WEATHER__COLUMN__CLOUDINESS__CELL_VALUE_UNDEFINED',
            defaultMessage: 'Nedefinované',
          });

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      datakey: 'temperature',
      name: intl.formatMessage({
        id: 'ESD__WEATHER__COLUMN__TEMPERATURE',
        defaultMessage: 'Teplota',
      }),
      width: 100,
      CellComponent: function Cell(props: TableFieldCellProps<WeatherReport>) {
        const value = props.value ? `${props.value}°C` : '';

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      datakey: 'windSpeed',
      name: intl.formatMessage({
        id: 'ESD__WEATHER__COLUMN__WIND_SPEED',
        defaultMessage: 'Rýchlosť vetra',
      }),
      width: 100,
      CellComponent: function Cell(props: TableFieldCellProps<WeatherReport>) {
        const value = props.value ? `${props.value} m/s` : '';

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ESD__WEATHER__COLUMN__CREATED',
        defaultMessage: 'Vytvorenie',
      }),
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}
