import {
  abortableFetch,
  DomainObject,
  EmptyComponent,
  useEventCallback,
} from '@eas/common-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useValidationSchema } from '../collection-schema';
import { CollectionToolbarAction } from '../custom-collection-toolbar';

export function useUnassignCollectionDialog<T extends DomainObject>(
  getUrl: (id: string, collectionId: string) => string
) {
  const callApi = useEventCallback(
    (id: string, collectionId: string, formData: T) => {
      return abortableFetch(`${getUrl(id, collectionId)}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      });
    }
  );

  return {
    callApi,
  };
}

export function resolveUnassignAction<T extends DomainObject>(
  props: CollectionToolbarAction<typeof useUnassignCollectionDialog<T>, T>
) {
  return {
    hide: false,
    useApi: useUnassignCollectionDialog,
    Dialog: EmptyComponent,
    label: (
      <FormattedMessage
        id="EAS__COLLECTION__UNASSIGN_LABEL"
        defaultMessage="Odebrat"
      />
    ),
    title: (
      <FormattedMessage
        id="EAS__COLLECTION__UNASSIGN_TITLE"
        defaultMessage="Odebrat"
      />
    ),
    text: (
      <FormattedMessage
        id="EAS__COLLECTION__DELETE_TEXT"
        defaultMessage="Skutečně chcete odebrat položku?"
      />
    ),
    initValues: (values: T) => values,
    dialogWidth: 400,
    useSchema: useValidationSchema,
    ...props,
  };
}
