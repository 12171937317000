import React from 'react';
import { AuthoredEvidence, DictionaryEvidence } from '@eas/common-web';

export const Fields = () => {
  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <DictionaryEvidence.FieldName />
      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
};
