import React, { useContext } from 'react';
import {
  DetailActionButton,
  FormTextField,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { FormattedMessage } from 'react-intl';
import { useClosePartialJournalDialog } from './dialog-actions/close-partial-journal-hook';
import { useSignPartialJournalByTimestamp } from './dialog-actions/sign-partial-journal-timestamp-hook';
import { Messages } from '@enums';
import { ErrorObject } from '@models';
import { useCancelPartialJournalDialog } from './dialog-actions/cancel-partial-journal-hook';
import * as Yup from 'yup';

export function PartialJournalActionbar() {
  const { showSnackbar } = useContext(SnackbarContext);

  const { callApi: closeApiCall, showButton: showCloseButton } =
    useClosePartialJournalDialog();

  const {
    callApi: signByTimestapApiCall,
    showButton: showSignByTimestampButton,
  } = useSignPartialJournalByTimestamp();

  const { callApi: cancelApiCall, showButton: showCancelButton } =
    useCancelPartialJournalDialog();
  return (
    <>
      {showCloseButton && (
        <DetailActionButton
          promptKey="CLOSE_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__BUTTON"
              defaultMessage="Zavrieť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__DIALOG_TEXT"
              defaultMessage="Skutočne chcete zavrieť dielčí denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={closeApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          onError={async (err: ErrorObject<string>) => {
            if (err.code && err.code in Messages.PartialJournal.CLOSE) {
              showSnackbar(...Messages.PartialJournal.CLOSE[err.code]);
            } else {
              showSnackbar(...Messages.Common.UNEXPECTED_ERROR);
            }
          }}
        />
      )}
      {showSignByTimestampButton && (
        <DetailActionButton
          promptKey="SIGN_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__BUTTON"
              defaultMessage="Podpísanie časovou pečiatkou"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať dielčí denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={signByTimestapApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey="CANCEL_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__BUTTON"
              defaultMessage="Zrušiť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TEXT"
              defaultMessage="Odošle sa notifikácia administrátorovi, ktorý musí zrušenie potvrdiť. Uveďte dôvod na zrušenie."
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={cancelApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
          isLocked={false}
          FormFields={() => (
            <FormTextField name="reason" label="Dôvod" required />
          )}
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          onSuccess={async () => {
            showSnackbar(
              'Žiadosť o zrušenie bola odoslaná administrátorovi.',
              SnackbarVariant.SUCCESS
            );
          }}
          /*  onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          } */
        />
      )}
    </>
  );
}
