/**
 * Role permissions
 */
export enum PermissionRole {
  /**
   * Prehľad rolí
   */
  ROLE_LIST = 'ROLE_LIST',

  /**
   * Detail role
   */
  ROLE_DETAIL = 'ROLE_DETAIL',

  /**
   * Vytvorenie role
   */
  ROLE_CREATE = 'ROLE_CREATE',

  /**
   * Editácia role
   */
  ROLE_UPDATE = 'ROLE_UPDATE',

  /**
   * Zmazanie role
   */
  ROLE_DELETE = 'ROLE_DELETE',

  /**
   * Zneplatnenie role
   */
  ROLE_INVALIDATE = 'ROLE_INVALIDATE',

  /**
   * Obnovenie role
   */
  ROLE_REVALIDATE = 'ROLE_REVALIDATE',
}
