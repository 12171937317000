import React from 'react';
import {
  SupplierDictionaryActionBar,
  SupplierDictionaryField,
} from '@composite/supplier-dictionary-field';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { Me, Supplier, Work, WorkCategory } from '@models';
import {
  SUPPLIER_WORK_CATEGORY_DICTIONARY,
  SUPPLIER_WORK_DICTIONARY,
} from '@modules/suppliers/supplier-hooked-sources';
import { useContext } from 'react';
import { EvidenceApiUrl } from '@enums';
import { FormattedMessage } from 'react-intl';
import { useFilterFields } from './work-filter-fields';
import { useColumns } from './work-columns';
import { WorkCreateDialog } from './dialogs/work-create-dialog';
import { useValidationSchema as useWorkCategorySchema } from '@modules/work-category/work-category-schema';
import { useValidationSchema as useWorkSchema } from '@modules/work/work-schema';

export function SupplierWorkFields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const supplierId = data?.id || '';
  const workSource = hookedSources[SUPPLIER_WORK_DICTIONARY]?.source;
  const workCategoriesSource =
    hookedSources[SUPPLIER_WORK_CATEGORY_DICTIONARY]?.source;

  const disabled =
    mode !== DetailMode.VIEW || supplierId !== user?.supplier?.id;
  const workFilters = useFilterFields();

  return (
    <>
      {data && <SupplierDictionaryActionBar sourceUrl={EvidenceApiUrl.WORK} />}
      <SupplierDictionaryField<WorkCategory>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__WORK_CATEGORY__TITLE"
            defaultMessage="Dodávateľský číselník - Typ vedenia"
          />
        }
        sourceUrl={EvidenceApiUrl.WORK_CATEGORY}
        source={workCategoriesSource}
        disabled={disabled}
        useSchema={useWorkCategorySchema}
      />
      <SupplierDictionaryField<Work>
        supplierId={supplierId}
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__WORK__TITLE"
            defaultMessage="Dodávateľský číselník - Typ vykonanej práce"
          />
        }
        sourceUrl={EvidenceApiUrl.WORK}
        source={workSource}
        disabled={disabled}
        createDialog={WorkCreateDialog}
        useColumns={useColumns}
        filters={workFilters}
        useSchema={useWorkSchema}
      />
    </>
  );
}
