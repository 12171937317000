/**
 * Region attachment permissions
 */
export enum PermissionJournalEntryAttachment {
  /**
   * Prehľad príloh na dennom zázname
   */
  JOURNAL_ENTRY_ATTACHMENT_LIST = 'JOURNAL_ENTRY_ATTACHMENT_LIST',

  /**
   * Detail prílohy na dennom zázname
   */
  JOURNAL_ENTRY_ATTACHMENT_DETAIL = 'JOURNAL_ENTRY_ATTACHMENT_DETAIL',

  /**
   * Pridanie prílohy na denný záznam
   */
  JOURNAL_ENTRY_ATTACHMENT_CREATE = 'JOURNAL_ENTRY_ATTACHMENT_CREATE',

  /**
   * Editácia prílohy denného záznamu
   */
  JOURNAL_ENTRY_ATTACHMENT_UPDATE = 'JOURNAL_ENTRY_ATTACHMENT_UPDATE',

  /**
   * Zmazanie prílohy denného záznamu
   */
  JOURNAL_ENTRY_ATTACHMENT_DELETE = 'JOURNAL_ENTRY_ATTACHMENT_DELETE',

  /**
   * Podpísanie prílohy denného záznamu
   */
  JOURNAL_ENTRY_ATTACHMENT_SIGN = 'JOURNAL_ENTRY_ATTACHMENT_SIGN',
}
