import { FileTableActionCell } from '@components/file-table-action-cell/file-table-action-cell';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Permission } from '@enums';
import { Me } from '@models';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

export function useAttachmentsColumns<
  T extends DomainObject
>(): TableFieldColumn<T>[] {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const intl = useIntl();

  return [
    ...(hasPermission(
      Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_DETAIL
    )
      ? [
          {
            name: intl.formatMessage({
              id: 'ESD__ATTACHMENTS__TABLE__COLUMN__ACTIONS',
              defaultMessage: 'Stiahnuť',
            }),
            datakey: 'file.id',
            width: 70,
            CellComponent: FileTableActionCell,
          },
        ]
      : []),
    {
      name: intl.formatMessage({
        id: 'ESD__ATTACHMENTS__TABLE__COLUMN__FILE_NAME',
        defaultMessage: 'Názov prílohy',
      }),
      datakey: 'file.name',
      width: 300,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__ATTACHMENTS__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      width: 250,
    },

    {
      name: intl.formatMessage({
        id: 'ESD__ATTACHMENTS__TABLE__COLUMN__CREATED',
        defaultMessage: 'Vytvorenie',
      }),
      datakey: 'created',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__ATTACHMENTS__TABLE__COLUMN__UPDATED',
        defaultMessage: 'Posledná úprava',
      }),
      datakey: 'updated',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}
