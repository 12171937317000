import React, {
  ComponentPropsWithoutRef,
  ComponentType,
  ReactNode,
} from 'react';
import {
  Callback,
  CollectionField,
  DomainObject,
  Filter,
  ScrollableSource,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useDefaultColumns } from './supplier-dictionary-columns';

import { useFilterFields } from './supplier-dictionary-filter-fields';
import { useValidationSchema } from './supplier-dictionary-schema';
import * as Yup from 'yup';
import { DefaultSupplierDictionaryCreateDialog } from './dialogs/supplier-dictionary-create-dialog';
import exceptions from 'src/enums/messages/exception';

interface SupplierDictionaryFieldProps<T extends DomainObject>
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<T>>> {
  supplierId: string;
  refFilters?: Filter[];
  source: ScrollableSource<T>;
  title: ReactNode;
  sourceUrl: EvidenceApiUrl;
  createDialog?: ComponentType<{
    onConfirm?: Callback;
    onCancel?: () => void;
  }>;
  useSchema?: Yup.Shape<any, any>;
}

export function SupplierDictionaryField<T extends DomainObject>({
  source,
  title,
  sourceUrl,
  filters,
  createDialog = DefaultSupplierDictionaryCreateDialog,
  useSchema = useValidationSchema,
  useColumns = useDefaultColumns,
  ...overrides
}: SupplierDictionaryFieldProps<T>) {
  const defaultFilters = useFilterFields();

  return (
    <CollectionField<T>
      title={title}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => sourceUrl,
        Dialog: createDialog,
        initValues: () => ({
          active: true,
        }),
        useSchema,
        dialogWidth: 700,
      }}
      readAction={{
        getUrl: (collectionId) => `${sourceUrl}/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) => `${sourceUrl}/${collectionId}`,
        Dialog: createDialog,
        useSchema,
      }}
      deleteAction={{
        getUrl: (_, collectionId) => `${sourceUrl}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) => `${sourceUrl}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) => `${sourceUrl}/${collectionId}`,
      }}
      filters={[...defaultFilters, ...(filters ? filters : [])]}
      exceptions={exceptions}
      {...overrides}
    />
  );
}
