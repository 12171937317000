/**
 * Dictionary - Work category permissions
 */
export enum PermissionWorkCategory {
  /**
   * Prehľad typov vedenia
   */
  WORK_CATEGORY_LIST = 'WORK_CATEGORY_LIST',

  /**
   * Prehľad typov vedenia dodávateľa
   */
  WORK_CATEGORY_LIST_SUPPLIER = 'WORK_CATEGORY_LIST_SUPPLIER',

  /**
   * Detail typu vedenia
   */
  WORK_CATEGORY_DETAIL = 'WORK_CATEGORY_DETAIL',

  /**
   * Vytvorenie typu vedenia
   */
  WORK_CATEGORY_CREATE = 'WORK_CATEGORY_CREATE',

  /**
   * Editácia typu vedenia
   */
  WORK_CATEGORY_UPDATE = 'WORK_CATEGORY_UPDATE',

  /**
   * Zmazanie typu vedenia
   */
  WORK_CATEGORY_DELETE = 'WORK_CATEGORY_DELETE',

  /**
   * Zneplatnenie typu vedenia
   */
  WORK_CATEGORY_INVALIDATE = 'WORK_CATEGORY_INVALIDATE',

  /**
   * Obnovenie typu vedenia
   */
  WORK_CATEGORY_REVALIDATE = 'WORK_CATEGORY_REVALIDATE',

  /**
   * Prevzatie typov vedenia
   */
  WORK_CATEGORY_TAKEOVER = 'WORK_CATEGORY_TAKEOVER',

  /**
   * Import typu vedenia
   */
  WORK_CATEGORY_IMPORT = 'WORK_CATEGORY_IMPORT',
}
