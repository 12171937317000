import { Timesheet } from '@models';
import * as Yup from 'yup';

export function useCreateValidationSchema() {
  return Yup.object<Timesheet>().shape({
    people: Yup.mixed().nullable().required(),
    hours: Yup.number().nullable().required(),
    overtime: Yup.number().nullable().required(),
  });
}
export function useUpdateValidationSchema() {
  return Yup.object<Timesheet>().shape({
    hours: Yup.number().nullable().required(),
    overtime: Yup.number().nullable().required(),
  });
}
