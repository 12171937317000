import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useRoles } from '@modules/roles/role-api';
import { usePersons } from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function Fields() {
  const roles = useRoles();
  const persons = usePersons();
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ROLES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormTextField
          name="username"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__SURNAME"
              defaultMessage="Prihlasovacie meno"
            />
          }
          disabled
          required
        />

        <FormAutocomplete
          name="person"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__Person"
              defaultMessage="Osoba"
            />
          }
          source={persons}
          labelMapper={personLabelMapper}
          disabled
          required
        />

        <FormAutocomplete
          name="role"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__ROLE"
              defaultMessage="Rola"
            />
          }
          source={roles}
          required
        />
      </FormPanel>

      <DatedEvidence.DatedFields />
    </>
  );
}
