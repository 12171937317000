import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  FormAutocomplete,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { useSupplierWorkCategories } from '@modules/work-category/work-category-api';
import { FormattedMessage } from 'react-intl';
import { Me } from '@models';
import { useSupplierWorkTbpps } from '@modules/work-tbpp/work-tbpp-api';

export function WorkCreateDialog() {
  const { user } = useContext<UserContext<Me>>(UserContext);

  const workCategories = useSupplierWorkCategories();
  const tbpps = useSupplierWorkTbpps();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormAutocomplete
        name="category"
        source={workCategories}
        label={
          <FormattedMessage
            id="ESD__SUPPLIER_WORK__DETAIL__FIELD_LABEL__CATEGORY"
            defaultMessage="Typ vedenia"
          />
        }
        required
      />
      <DictionaryEvidence.FieldName />
      <FormTextField
        name="units"
        label={
          <FormattedMessage
            id="ESD__WORK__DETAIL__FIELD_LABEL__UNITS"
            defaultMessage="Jednotky práce"
          />
        }
        required
      />
      {showTBPP && (
        <FormAutocomplete
          name="tbpp"
          source={tbpps}
          label={
            <FormattedMessage
              id="ESD__WORK__DETAIL__FIELD_LABEL__TBPP"
              defaultMessage="TBPP"
            />
          }
        />
      )}
    </>
  );
}
