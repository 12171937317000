import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormFileField,
  FormPanel,
  FormTableField,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useSignaturesColumns } from '@composite/signatures-field/signatures.columns';
import { SignaturesTableFieldToolbar } from '../signatures/signatures-toolbar';
import { Attachment } from '@models';
import { FormImageField } from '@components/form/form-import-field/image-field/form-image-field';

export function AttachmentsReadDialog(props: any) {
  const signatureColumns = useSignaturesColumns();

  const values = useFormSelector((v: Attachment) => v);

  const isPDFAttachment = values?.file?.contentType?.includes('pdf');

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ATTACHMENTS_TABLE__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__ATTACHMENT"
              defaultMessage="Príloha"
            />
          }
          required
          accept={[
            '.jpg',
            '.JPG',
            '.png',
            '.PNG',
            '.bmp',
            '.BMP',
            '.gif',
            '.GIF',
            '.svg',
            '.SVG',
            '.jpeg',
            '.JPEG',
            '.pdf',
            '.PDF',
          ]}
          disabled
        />
        <FormTextField
          name="description"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
          disabled
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ATTACHMENTS_TABLE__PANEL_TITLE__SIGNATURES"
            defaultMessage="Podpísy"
          />
        }
        expandable
        defaultExpanded={isPDFAttachment}
      >
        <FormTableField
          name="signatures"
          columns={signatureColumns}
          ToolbarComponent={({ selectedIndex, setSelectedIndex }) =>
            SignaturesTableFieldToolbar(
              values.id,
              selectedIndex,
              setSelectedIndex,
              props.readApiCall
            )
          }
          maxRows={5}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          defaultSort={[
            {
              datakey: 'created',
              field: 'created',
              type: 'FIELD',
              order: 'ASC',
            },
          ]}
        />
      </FormPanel>

      {!isPDFAttachment && values?.file && (
        <FormImageField
          name="file"
          showFileField={false}
          //   label="Obrázok"
          height={400}
        />
      )}
    </>
  );
}
