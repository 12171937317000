import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  eqFilterParams,
  FormAutocomplete,
  FormContext,
  FormNumberField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useMechanisms } from '@modules/mechanisms/mechanism-api';
import { useMechanismCategories } from '@modules/mechanism-category/mechanism-category-api';
import { JournalEntryMechanism } from '@models';

export function MechanismCreateDialog() {
  const { setFieldValue } = useContext(FormContext);
  const { category, type } = useFormSelector(
    (values: JournalEntryMechanism) => ({
      category: values.category,
      type: values.type,
    })
  );

  const mechanismCategories = useMechanismCategories();
  const mechanisms = useMechanisms(
    category
      ? eqFilterParams({ field: 'category.id', value: category.id })
      : undefined
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormAutocomplete
          source={mechanismCategories}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__CATEGORY"
              defaultMessage="Kategória mechanizmu"
            />
          }
          name="category"
          notifyChange={() => {
            if (type) setFieldValue('type', null);
          }}
        />
        <FormAutocomplete
          source={mechanisms}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Názov mechanizmu"
            />
          }
          name="type"
          required
          notifyChange={(type: any) => {
            if (type) setFieldValue('category', type.category);
          }}
        />
        <FormAutocomplete
          source={mechanisms}
          labelMapper={(option) => option.licencePlate ?? ''}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__LICENCE_PLATE"
              defaultMessage="ŠPZ"
            />
          }
          name="type"
          required
          notifyChange={(type: any) => {
            if (type) setFieldValue('category', type.category);
          }}
        />
        <FormTextField
          name="type.supplier.name"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Dodávateľ mechanizmu"
            />
          }
          disabled
        />
        <FormNumberField
          name="hours"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__HOURS"
              defaultMessage="sm/mh"
            />
          }
          required
        />
        <FormNumberField
          name="distance"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__DISTANCE"
              defaultMessage="km - odhad"
            />
          }
          required
        />
      </FormPanel>
    </>
  );
}
