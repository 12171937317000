import React, { ReactNode } from 'react';
import { useStyles } from './content-unavailable-panel-styles';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';

interface ContentUnavailablePanelProps {
  title: ReactNode;
  reason: ReactNode;
}

export function ContentUnavailablePanel({
  title,
  reason,
}: ContentUnavailablePanelProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <WarningIcon className={classes.icon} />
      <span>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.reason}>{reason}</div>
      </span>
    </div>
  );
}
