/**
 * Dictionary - Absence permissions
 */
export enum PermissionAbsence {
  /**
   * Prehľad neprítomností
   */
  ABSENCE_LIST = 'ABSENCE_LIST',

  /**
   * Prehľad neprítomností dodávateľa
   */
  ABSENCE_LIST_SUPPLIER = 'ABSENCE_LIST_SUPPLIER',

  /**
   * Detail neprítomnosti
   */
  ABSENCE_DETAIL = 'ABSENCE_DETAIL',

  /**
   * Vytvorenie neprítomnosti
   */
  ABSENCE_CREATE = 'ABSENCE_CREATE',

  /**
   * Editácia neprítomnosti
   */
  ABSENCE_UPDATE = 'ABSENCE_UPDATE',

  /**
   * Zmazanie neprítomnosti
   */
  ABSENCE_DELETE = 'ABSENCE_DELETE',

  /**
   * Zneplatnenie neprítomnosti
   */
  ABSENCE_INVALIDATE = 'ABSENCE_INVALIDATE',

  /**
   * Obnovenie neprítomnosti
   */
  ABSENCE_REVALIDATE = 'ABSENCE_REVALIDATE',

  /**
   * Prevzatie neprítomností
   */
  ABSENCE_TAKEOVER = 'ABSENCE_TAKEOVER',

  /**
   * Import neprítomostí
   */
  ABSENCE_IMPORT = 'ABSENCE_IMPORT',
}
