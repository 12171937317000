import { abortableFetch, Params } from '@eas/common-web';

export const cronFormatCheck = (value: string) => {
  const regexByField: any = {};
  //  regexByField['sec'] = '[0-5]?\\d';
  regexByField['min'] = '[0-5]?\\d';
  regexByField['hour'] = '[01]?\\d|2[0-3]';
  regexByField['day'] = '0?[1-9]|[12]\\d|3[01]|L|W|LW';
  regexByField['month'] = '[1-9]|1[012]';
  regexByField['dayOfWeek'] = '[0-7]';
  // regexByField['year'] = '|\\d{4}';

  [
    'min',
    'hour',
    'day',
    'month',
    'dayOfWeek',
    // 'year'
  ].forEach((field) => {
    let range =
      '(?:' +
      regexByField[field] +
      '|\\*)' +
      '(?:' +
      '(?:-|/|,' +
      ('dayOfWeek' === field ? '|#' : '') +
      ')' +
      '(?:' +
      regexByField[field] +
      '|\\*)' +
      ')?';
    if (field === 'day') range += '(?:L|W)?';
    if (field === 'dayOfWeek') range += '(?:L)?';
    regexByField[field] =
      (field === 'day' || field === 'dayOfWeek' ? '\\?|' : '') +
      '\\*|' +
      range +
      '(?:,' +
      range +
      ')*';
  });

  const monthValues = 'JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC';
  const monthRange = '(?:' + monthValues + ')(?:(?:-)(?:' + monthValues + '))?';
  regexByField['month'] +=
    '|\\?|\\*|' + monthRange + '(?:,' + monthRange + ')*';

  const dayOfWeekValues = 'MON|TUE|WED|THU|FRI|SAT|SUN';
  const dayOfWeekRange =
    '(?:' + dayOfWeekValues + ')(?:(?:-)(?:' + dayOfWeekValues + '))?';
  regexByField['dayOfWeek'] +=
    '|\\?|\\*|' + dayOfWeekRange + '(?:,' + dayOfWeekRange + ')*';

  return new RegExp(
    '^\\s*($' +
      '|#' +
      '|\\w+\\s*=' +
      '|' +
      /* '(' +
      regexByField['sec'] +
      ')\\s+' + */
      '(' +
      regexByField['min'] +
      ')\\s+' +
      '(' +
      regexByField['hour'] +
      ')\\s+' +
      '(' +
      regexByField['day'] +
      ')\\s+' +
      '(' +
      regexByField['month'] +
      ')\\s+' +
      '(' +
      regexByField['dayOfWeek'] +
      // ')(|\\s)+' +
      // '(' +
      // regexByField['year'] +
      ')' +
      ')\\s*$'
  ).test(value);
};

export function publicDictionaryListItems(listUrl: string, params: Params) {
  return abortableFetch(`${listUrl}/public`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
}
