import { Params, useAutocompleteSource } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { Absence } from '@models';

export function useAbsences(params?: Params) {
  return useAutocompleteSource<Absence>({
    url: `${EvidenceApiUrl.ABSENCE}/autocomplete`,
    params,
  });
}
