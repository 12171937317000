import { PersonType } from '@models';
import { Supplier } from 'src/enums/permission';
import * as Yup from 'yup';
import { PersonSupplierAssignments } from './supplier-assignments-field';

export function useValidationSchema() {
  return Yup.object<PersonSupplierAssignments>().shape({
    person: Yup.mixed<PersonType>().nullable().required(),
    supplier: Yup.mixed<Supplier>().nullable().required(),
  });
}
