import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormContext,
  FormDecimalField2,
  FormLocalDateField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { useAbsences } from '@modules/absence/absence-api';
import { personLabelMapper } from '@utils/label-mapper';
import { useProfessions } from '@modules/profession/profession-api';

export function TimesheetUpdateDialog() {
  const { setFieldValue } = useContext(FormContext);

  const persons = usePersons();
  const professions = useProfessions();
  const absences = useAbsences();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormAutocomplete
          source={persons}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__PERSON"
              defaultMessage="Osoba"
            />
          }
          name="person"
          required
          disabled
          labelMapper={personLabelMapper}
        />
        <FormLocalDateField
          name="date"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__DATE"
              defaultMessage="Dátum"
            />
          }
          disabled
        />
        <FormDecimalField2
          name="hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__HOURS"
              defaultMessage="Odpracované hodiny"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
        />
        <FormDecimalField2
          name="overtime"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__OVERTIME"
              defaultMessage="Nadčas"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
        />
        <FormAutocomplete
          source={professions}
          name="profession"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__WORK_UNITS"
              defaultMessage="Pracovná pozícia"
            />
          }
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__ABSENCE"
            defaultMessage="Neprítomnosť"
          />
        }
      >
        <FormAutocomplete
          source={absences}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE"
              defaultMessage="Neprítomnosť"
            />
          }
          name="absence.type"
          notifyChange={(val) => {
            if (!val) setFieldValue('absence', null);
          }}
        />
        <FormDecimalField2
          name="absence.hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_HOURS"
              defaultMessage="Neprítomnosť - hodiny"
            />
          }
          decimalDigits={1}
          fixedDecimalDigits={false}
        />
        <FormTextField
          name="absence.comment"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_COMMENT"
              defaultMessage="Komentár"
            />
          }
        />
      </FormPanel>
    </>
  );
}
