/**
 * Region person partial journal assignment permissions
 */
export enum PermissionPersonAssignmentPartialJournal {
  /**
   * Prehľad členov dielčieho denníka
   */
  PERSON_ASSIGNMENT_PARTIAL_JOURNAL_LIST = 'PERSON_ASSIGNMENT_PARTIAL_JOURNAL_LIST',

  /**
   * Pridávanie členov dielčieho denníka
   */
  PERSON_ASSIGNMENT_PARTIAL_JOURNAL_CREATE = 'PERSON_ASSIGNMENT_PARTIAL_JOURNAL_CREATE',

  /**
   * Odstraňovanie členov dielčieho denníka
   */
  PERSON_ASSIGNMENT_PARTIAL_JOURNAL_DELETE = 'PERSON_ASSIGNMENT_PARTIAL_JOURNAL_DELETE',
}
