import React, { useContext } from 'react';
import {
  NavigationContext,
  useFirstRender,
  UserContext,
} from '@eas/common-web';
import { LoginNew } from '@modules/login-new/login-new';
import { EvidenceBrowserUrl } from './enums/evidence-url';

export function AppPublic() {
  const { user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);

  useFirstRender(async () => {
    if (user) {
      navigate(EvidenceBrowserUrl.HOME);
    }
  });
  return (
    <>
      <LoginNew />
    </>
  );
}
