import { PersonSupplierAssignments } from '@composite/supplier-assignments-collection/supplier-assignments-field';
import {
  ApiFilterOperation,
  Filter,
  ListSource,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { AssignmentType, EvidenceApiUrl } from '@enums';
import { PersonAutocomplete, PersonType } from '@models';

export function usePersons(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/autocomplete`,
    params,
  });
}
export function useAssignedPersons(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/assignment/autocomplete`,
    params,
  });
}

export function usePersonsInlineTableAutocomplete(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/autocomplete`,
    apiUrl: EvidenceApiUrl.PERSON,
    params,
  });
}

export function usePersonAssignedList({
  skip,
  query,
  primaryJournalId,
  supplierId,
  partialJournalId,
}: /*   userIds, */
//,
{
  skip: boolean;
  // journalEntryId: string;
  query?: string;
  primaryJournalId?: string | undefined;
  partialJournalId?: string | undefined;
  supplierId?: string | undefined;

  /*  userIds?: string[]; */
}) {
  const filters: Filter[] = [];

  if (supplierId) {
    filters.push({
      field: 'supplier.id',
      operation: ApiFilterOperation.EQ,
      value: supplierId,
    });
  }

  if (primaryJournalId) {
    filters.push({
      field: 'primaryJournal.id',
      operation: ApiFilterOperation.EQ,
      value: primaryJournalId,
    });
  }
  if (partialJournalId) {
    filters.push({
      field: 'partialJournal.id',
      operation: ApiFilterOperation.EQ,
      value: partialJournalId,
    });
  }

  if (query) {
    filters.push({
      operation: ApiFilterOperation.FTX,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<ListSource<PersonType>>(
    `${EvidenceApiUrl.PERSON}/assignment/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        filters,
      }),
      skip,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function usePersonAssignedSupplierList({
  skip,
  personId,
}: {
  skip: boolean;
  personId: string;
}) {
  const filters: Filter[] = [
    {
      field: 'person.id',
      operation: ApiFilterOperation.EQ,
      value: personId,
    },
    {
      field: 'type.id',
      operation: ApiFilterOperation.EQ,
      value: AssignmentType.SUPPLIER,
    },
  ];

  const [result, loading, reset, setLoading] = useFetch<
    ListSource<PersonSupplierAssignments>
  >(`${EvidenceApiUrl.PERSON}/assignment/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size: -1,
      filters,
    }),
    skip,
  });

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}
